.projImg{
    min-height: 240px;
    max-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
} 
.projImage{
    width: 100vw;
    height: 32vw;
    max-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;  
}

.proj-width{
    padding: 0 6px;
}
.jobDescribe a{
    text-decoration: none;
}
.jobDescribe{
    padding-top: 96px;
}

/* for blogs */
.eightthDiv .projectDiv { min-height: 437px; }
.jobDescribeContainer .proj-width .projectDiv { min-height: 437px; }
#jobDetail{
    max-width: 90%;    
}

.jobDescribe .blogsArea {
    color: #ffffff;
    background-color: #292626;
    padding: 30px 40px;
    border-radius: 10px;
}
.jobDescribe .blogsArea h1 {
    color: #051639;
    padding: 20px;
    font-weight: bold;
    background-color: #ffffff;
    margin-bottom: 20px;
}
.jobDescribe .blogsArea h2 {
    color: rgb(129, 129, 135);
    padding-bottom: 20px;
    text-decoration: underline;
}
.jobDescribe .blogsArea h3 {
    color: rgb(129, 129, 135);
    padding-bottom: 20px;
    text-decoration: underline;
}
.jobDescribe .blogsArea li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0.5em;
    color: #ffffff;
}

@media  screen and (max-width: 1260px){
    .jobDescribe{
        padding: 96px 3vw;
    }
}
@media screen and (max-width: 767px){

    .projImg div{
        height: 16vw;
    }
}
@media screen and (max-width: 575px){
    .projImg div {
        height: 39vw;}
      
}