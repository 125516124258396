.darkMode .quotationForm input[type=text], 
.darkMode .quotationForm textarea {
    background-color: #1F2D4D !important;
}
.quotationContainer {
    background: 
    linear-gradient(260deg, rgba(5, 22, 55, 0) 8.24%, #051637 70.52%),
    url('../images/spaceShip.png');
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #051639;
    height: 100vh;
    width: 100%;
}
.quotationContainerAr {
    background: 
    linear-gradient(101deg, rgba(5, 22, 55, 0) 8.24%, #051637 70.52%),
    url('../images/spaceShipFlip.png');
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #051639;
    height: 100vh;
    width: 100%;
}

.quotationForm{
    padding: 12vw 5vw 10vw 5vw;
}

.quotationFormAr{
    padding: 12vw 5vw 10vw 5vw;
}

.quotImg div{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}
.quotImg{
    padding: 0;
    padding-bottom: 6vw;
}
.quotationForm input[type=text]{
    background: #FFFFFF;
/* Gray/300 */
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;

/* identical to box height, or 150% */


/* Gray/900 */

color: #101828;
border: 1px solid #D0D5DD;
/* Shadow/xs */
padding: 1.8em 3em;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
width: 100%;
height: 44px;
}

.quotationForm select {
    background-color: #1F2D4D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    border: 1px solid #1F2D4D;
    padding: 0px 15px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 100%;
    height: 60px;
}

.quotationForm input[type=text]:focus{
    outline: none !important;
    border: 1px solid #1851DF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #C5D4F9;
}
input[type=text]:hover, textarea:hover{
    outline: none !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #C5D4F9;


}

.quotationForm textarea:focus{
    outline: none !important;
    border: 1px solid #1851DF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #C5D4F9;
}

input::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}

.error{
    outline: none !important;
    border: 1px solid #FDA29B !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
}

.errMsg{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #F04438;
    margin-top: 0.3em;
}

.quotationForm textarea{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;

    background: #FFFFFF;
/* Gray/300 */
/* padding: 1em; */
padding: 16px;
border: 1px solid #D0D5DD;
/* Shadow/xs */

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
width: 100%;
}
.quotationForm label{
    font-family: 'Montserrat';
font-style: normal;
margin: 1em 0;
font-weight: 500;
font-size: 14px;
color: #344054;
display: block;
}
.quotationForm .nopadding{
    padding-right: 1em !important;
}
.noPadding{
   padding: 0 !important;
   max-width: 31%;
}
.contDiv-flex{
    display: flex;
width: 100%;
    justify-content: space-between;
}
#submit{
    background: #1851DF;
/* Primary/500 */

border: 1px solid #1851DF;
/* Shadow/xs */

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
border: none;
width: 100%;
padding: 1.1em 3em;
color: #FFFFFF;
transition: 0.7s;

}

#submit:hover{
    background-color: #62C4F9;
     
}
#quotRequest{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
  
    letter-spacing: -0.02em;
    

    
    color: #101828;
    
}
#quotReach{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
 
    color: #475467;
    
}
#quotReach a{
    color:  #3B6DEA;
    cursor: pointer;
}
#services{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
margin-top: 1em;
color: #344054;
}
.services label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  display: inline;
    color: #344054;
    margin-left:0.5em;
}
.services div{
    margin-bottom: 1em;
}
.services input{
    cursor: pointer;
}
#quotDiv{
    padding:96px 6vw;
    background: #F9FAFB;
    text-align: center;
}
#contact{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #3B6DEA;
    
}
#hear{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 36px;


color: #101828;
}
#chat{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 20px;

color: #475467;

}
.contactIcon{
    width: 48px;
height: 48px;
border-radius: 10px;
display: flex;
color: white;
align-items:center;
justify-content: center;
background: #1851DF;
margin-bottom: 3em;
}
#contType{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
   
    
    color: #101828;
}
#contTxt{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  
    
    color: #475467;
}
#cont{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
 margin-bottom: 0;
    color: #0F338C;
    direction: ltr;
}
.strech .contDiv{
height: 100%;
}

.containCheck input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
 
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  transform: translateY(-0.065em);
  display: inline-flex;
  place-content: center;
  }
  
  .containCheck input[type="checkbox"]::before {
  content:"";
    width: 100%;
    height: 100%;
    border-radius: 6px;

    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em  #BCE6FD;
 }
 .containCheck svg{
     position: absolute;
display: none;
     left: -1px;
 padding-bottom:  1.5px;
     pointer-events: none; 
 }
 .containCheck{
     position: relative;
 }
 .underline{
     text-decoration: underline;
 }
 input[type="checkbox"]:checked + svg{
     display: initial;
 }
  .containCheck input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  .containCheck input[type="checkbox"]:checked{
  border: 1px solid #1851DF;}
  .labelCheck  {
      position: relative;
bottom: 0.2em;
margin-left: 0.3em !important;
  }

.input-wrapper {
    position: relative;
    width: 100%;
}

.input-wrapper .input-icon {
    position: absolute;
    top: 38%;
    right: 20px;
    left: 20px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1.2rem;
    pointer-events: none;
}

/* Error message styling */
.input-wrapper .errMsg {
    height: 1rem;
}


@media screen and (max-width: 1200px){
    .quotationContainer {
        background-position: right center;
    }

    .quotationContainerAr {
        background-position: left center;
    }

    .quotationForm {
        padding: 15vw 5vw 2vw 5vw;
    }

    .quotationFormAr {
        padding: 15vw 5vw 2vw 5vw;
    }

    .quotationContainer {
        height: auto;
    }

    .quotationContainerAr {
        height: auto;
    }

    .quotationForm select {
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 964px){
    .quotImg div{
        background-position: center;
    }
}

@media screen and (max-width: 991px){

    .noPadding{
        max-width: none;
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }
}

@media screen and (max-width: 750px){
    .quotationForm {
        padding: 22vw 4vw 5vw 2vw;
    }

    .quotationFormAr {
        padding: 22vw 2vw 5vw 4vw;
    }

    .quotationContainer {
        height: auto;
    }

    .quotationContainerAr {
        height: auto;
    }

    .quotationForm select {
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 575px){
   
    .quotImg div{
       display: none;
    }
    #quotRequest{
        font-size: 30px;

    }
    #quotReach{
        font-size: 18px;

    }
    #quotReach a{
        display: block;
    }
 
    #contact{
        font-weight: 500;
font-size: 14px;
    }
    #hear{
        font-size: 30px;
        line-height: 38px;
    }
    #chat{
        font-weight: 400;
font-size: 18px;
    }
    .strech{
        margin-bottom: 2em;
    }
    #submit{
        margin-top: 0;
    }
}

@media screen and (max-width: 460px){
    .quotationForm {
        padding: 30vw 2vw 5vw 5vw;
    }

    .quotationFormAr {
        padding: 30vw 5vw 5vw 2vw;
    }
}
