#teamMember{
    padding: 96px 6vw;
    padding-bottom: 0 !important;
}
.member{
    border: 1px solid #E4E7EC;
border-radius: 8px;
text-align: center;
padding: 1em 0;
}
.member:hover{
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}
.team-padding{
    padding: 0 7px;
}
#memberImg{
    height: 10vw;
    width: 10vw;
    border-radius: 50%;
background-repeat: no-repeat;
background-size: cover;
margin: auto;
    margin-bottom: 1em;
}
#memberName{
 
font-style: normal;
font-weight: 600;
font-size: 20px;

margin-bottom: 0.5em;
color: #101828;
}
#memberPos{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom: 3em;
color: #667085;

}
.memberSocials{
    display: flex;
    justify-content: center;
border-radius: 8px;
}
.memberSocials button{
 background-color: white;
    border: 1px solid #E4E7EC;
border-radius:50%;
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
}
#twitter{
    color: #1DA1F2;;
}
#fb{
  
    color: #1877F2;
}
#li{
    color: #0A66C2;
    margin: 0 1em;
}

#niceTeam{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
color: #344054;

}
.ourTeam{
    text-align: center;
}
.ourTeam #contact{
font-weight: 600;

}
.ourTeam #hear{
    font-weight: 600;
   
    }

    .ourTeam #chat{
        max-width: 700px;
        margin: auto;
        margin-bottom: 2em;
    }
    #member{
        background-repeat: no-repeat;
        background-size: cover;
        height: 30vw;
        border-radius: 6px;
      display: flex;
      background-position: center;
      flex-direction: column;
      justify-content: space-between;
    }
    #member #memberData{
        text-align: center;
        /* White */
        padding: 1em 0;
width: 92%;
margin: auto;
margin-bottom: 0;
background: #FFFFFF;
backdrop-filter: blur(12px);
border-radius: 8px;

/*transition: width 0.4s;*/
position: relative;
top: 3em;
box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    }
    #member #memberName{
        margin-bottom: 0;
    }
    #member #memberPos{
        margin-bottom: 0;
    }
    .OurPrinciples{
        background-color: white !important;
    }
    .OurPrinciples .square{
width: 100%;
border: none !important;
    }
    .square button{
        font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;

color: #1851DF;
    }
    .gy-10 {
        --bs-gutter-y: 5rem;
    }
#memberSocials{
    background: rgba(255, 255, 255, 0.3);
    padding: 0.8em 0;
    border-radius: 8px;
    width: 90%;
    margin: auto;
  text-align: center;
position: relative;
top: 1.5em;
transition: all 0.4s linear;
opacity: 0;
display: flex;
justify-content: center;
}
#fb{

    color: #1877F2 !important;
}
#lin{
    margin:0 32px;
    color: #1877F2 !important;
}
#memberSocials button{
  
   border: none;
 height: 30px;
 width: 30px;
display: flex;
justify-content: center;
align-items: center;
   background: #FFFFFF;
   color:  #1DA1F2;
   border-radius: 50%}
   .img-gradient:hover #memberSocials{
      
    opacity: 1;
   }

 

.img-gradient{
    position:relative;

  }
  
  .img-gradient:after {/*
opacity: 0;    content:'';
    transition: all 0.4s linear;*/
}
  /* 
  #002f4b,#dc4225 
  Convert HEX to RGBA - http://hex2rgba.devoth.com/
  */
   .img-gradient:hover:after {/*
    content:'';
    opacity: 1; 
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;
   background-image: linear-gradient(180deg, rgba(131, 214, 228, 0.82) 0%, rgba(131, 214, 228, 0) 0.01%, rgba(16, 147, 169, 0.82) 60.3%);
 */
}
  .img-gradient img{
    display:block;
  }
.teamSwiper{
    display: none;
}

@media screen and (max-width: 767px){
    #member{
        height: 300px;
    }
    .teamImg{
        margin-top: 3em ;
   
      }
}
  @media screen and (max-width: 575px){
      .teamGet{
          margin-top: 1em !important;
      }
      .teamImg{
        margin-top: 2em ;
   
      }
    #memberSocials{
        position: static;
        padding: 0.3em 0;
        margin-bottom: 0.5em;

    }
    #member #memberData{
        position: static;
        padding: 0.3em 0;
        border-radius: 8px 8px 0 0;
    }
    .img-gradient{
        max-width: 282px;
        margin: auto;
    }
    .teamSwiper .swiper-button-next,  .teamSwiper .swiper-button-prev{
top: 94% !important;
        }
    
    .teamSwiper{
        display: block;
        height: 380px;
    }
    .OurPrinciples{
padding-top: 0;
    }
  .teamDiv{
display: none;
  }}