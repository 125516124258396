.products-container2 {
    background-color: #184b80;
}
.products-container2 .BdaitBluLogo {
    background-image: url("../images/bdaiatBlueLogo.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    position: absolute;
    top: 4em;
    left: 4em;
}
.kites .besallaTextCol {
    text-align: left;
    padding: 10em 0em 4em 4em;
}
.kites .arabicStyleBesallaTextCol {
    text-align: right;
    padding: 10em 8em 4em 0px;
}
.kites .besallaImgsCol {
    position: relative;
    padding: 10em 0em 3em 0px;
}

.kites .besallaTextCol .besallaLogo {
    background-image: url("../images/kitesLogo.png");
    background-size: contain;
    background-position: left;
    height: 9em;
    background-repeat: no-repeat;
    margin-bottom: 1em;
}
.kites .besallaTextCol .arabicStyleBesallaLogo {
    background-image: url("../images/kitesLogo.png");
    background-size: contain;
    background-position: right;
    height: 9em;
    background-repeat: no-repeat;
    /* margin-left: 22em; */
    margin-bottom: 1em;
}

.kites .besallaImgsCol .bsImgOne {
    background-image: url("../images/chefFlipped.png");
    background-size: contain;
    background-position: right;
    width: 100%;
    height: 35em;
    background-repeat: no-repeat;
    /* margin-left: -115px; */
}
.kites .besallaImgsCol .arabicStyleBsImgOne {
    background-image: url("../images/chef.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 35em;
    background-repeat: no-repeat;
    margin-left: -115px;
}
.kites .besallaImgsCol .bsImgTwo {
    position: absolute;
    top: 15em;
    right: 7em;
    z-index: 1;
    background-image: url("../images/besallaMob.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 33em;
    background-repeat: no-repeat;
}
.kites .besallaImgsCol .arabicStyleBsImgTwo {
    position: absolute;
    top: 15em;
    left: 3em;
    z-index: 1;

    background-image: url("../images/besallaMob.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 33em;
    background-repeat: no-repeat;
    /* margin-top: -25em;
    margin-left: 3em; */
}

.kites .besallaTextCol .besallaText h1 {
    color: #ffb345;
    font-size: 40px;
    font-family: 'Cairo';
    font-weight: bold;
    margin-bottom: 20px;
}

.kites .besallaTextCol .besallaText .besallaTextP {
    color: #ffffff;
    font-size: 24px;
    font-family: 'Cairo';
    font-weight: 300;
    direction: ltr;
}
.kites .besallaTextCol .besallaText .arabicStyleBesallaTextP {
    color: #ffffff;
    font-size: 24px;
    font-family: 'Cairo';
    font-weight: 300;
    direction: rtl;
}

.kites .besallaTextCol .besallaText .besallaTextP2 {
    color: #0E0E0E;
    font-size: 25px;
    font-family: 'Cairo';
    font-weight: bold;
}
.kites .besallaTextCol .besallaText .besallaTextInput {
    padding: 15px;
    border-radius: 15px;
    border: 2px solid #000000;
    text-align: left;
    width: 50%;
    color: #000000 !important;
    direction: ltr;
}
.kites .besallaTextCol .besallaText .arabicStyleBesallaTextInput {
    padding: 15px;
    border-radius: 15px;
    border: 2px solid #000000;
    text-align: right;
    direction: rtl;
}
.kites .besallaTextCol .besallaText .besallaTextBtn {
    padding: 15px 25px 15px 25px;
    border: 2px solid #000000;
    border-radius: 10px;
    background-color: #ffb345;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Cairo';
    font-weight: bold;
    position: absolute;
    margin-right: -4em;
}
.kites .besallaTextCol .besallaText .arabicStyleBesallaTextBtn {
    padding: 15px 25px 15px 25px;
    border: 2px solid #000000;
    border-radius: 10px;
    background-color: #ffb345;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Cairo';
    font-weight: bold;
    position: absolute;
    margin-left: -4em;
}
.kites .besallaTextCol .besallaText .besallaTextBtn:hover {
    background-color: #184b80;
    color: #ffb345;
}
.kites .besallaTextCol .besallaText .besallaTextBtn:active { border: 2px solid #FFF302; }


.aboutKites { 
    background-color: #FFFFFF;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    font-family: 'Cairo';
    padding-bottom: 3em;
}
.aboutKites .besallaTextCol {
    padding-top: 2em;
}
.aboutKites .kitesRow {
    padding: 2em 2em 0em 6em;
}
.aboutKites .arabicStyleKitesRow {
    padding: 2em 6em 0em 2em;
}
/* .aboutKites .besallaTextCol .besallaText { margin-right: 8em; } */
.aboutKites .besallaImgCol .besallaLogo {
    background-image: url("../images/KitesIcon.svg");
    background-size: contain;
    background-position: right;
    height: 7em;
    background-repeat: no-repeat;
    /* margin-left: 60em; */
    /* margin-right: 7em;
    margin-bottom: 3em; */
}
.aboutKites .besallaTextCol .besallaText .besallaTextH1 {
    color: #114389;
    font-size: 45px;
    font-family: 'Cairo';
    font-weight: bolder;
    text-align: left;
}
.aboutKites .besallaTextCol .besallaText .arabicStyleBesallaTextH1 {
    color: #114389;
    font-size: 45px;
    font-family: 'Cairo';
    font-weight: bolder;
    text-align: right;
    /* padding-right: 2.5em; */
}
.aboutKites .aboutCol1{
    padding: 3em 10em 0em 0em;
    text-align: left;
}
.aboutKites .arabicStyleAboutCol1{
    padding: 3em 0em 0em 10em;
    text-align: right;
}
.aboutKites .aboutCol2 {
    padding: 3em 5em 0em 8em;
    text-align: left;
}
.aboutKites .arabicStyleAboutCol2 {
    padding: 3em 8em 0em 5em;
    text-align: right;
}
.aboutKites .aboutCol3{
    padding: 0em 10em 3em 0em;
    text-align: left;
}
.aboutKites .arabicStyleAboutCol3{
    padding: 0em 0em 3em 10em;
    text-align: right;
}
.aboutKites .aboutCol4 {
    padding: 0em 5em 3em 8em;
    text-align: left;
}
.aboutKites .arabicStyleAboutCol4 {
    padding: 0em 8em 3em 5em;
    text-align: right;
}
.aboutKites .aboutColRes { padding: 0em 13em 5em 0em; }
.aboutKites .arabicStyleAboutColRes { padding: 0em 0em 5em 13em; }
.aboutKites .aboutCol5 {
    padding: 1em 2em 0em 2em;
    text-align: right;
    border: 2px solid #000000;
    border-radius: 10px;
    box-shadow: 5px 5px 0 0 #ffb345;
    /* width: 73%;
    margin-left: 13em; */
    background-color: #184b80;
}
.aboutKites .aboutCol6 {
    padding: 0em 5em 5em 8em;
    text-align: left;
}
.aboutKites .arabicStyleAboutCol6 {
    padding: 0em 8em 5em 5em;
    text-align: right;
}
.aboutKites .aboutCol2 .bsAboutImg2 {
    background-image: url("../images/featureImg1.svg");
    background-size: contain;
    background-position: left;
    height: 7em;
    background-repeat: no-repeat;
}
.aboutKites .arabicStyleAboutCol2 .bsAboutImg2 {
    background-image: url("../images/featureImg1.svg");
    background-size: contain;
    background-position: right;
    height: 7em;
    background-repeat: no-repeat;
    /* margin-bottom: 2em; */
    /* margin-left: 22em; */
}
.aboutKites .aboutCol1 .bsAboutImg1 {
    background-image: url("../images/featureImg2.svg");
    background-size: contain;
    background-position: left;
    width: 100%;
    height: 7em;
    background-repeat: no-repeat;
}
.aboutKites .arabicStyleAboutCol1 .bsAboutImg1 {
    background-image: url("../images/featureImg2.svg");
    background-size: contain;
    background-position: right;
    width: 100%;
    height: 7em;
    background-repeat: no-repeat;
    /* margin-bottom: 2em; */
    /* margin-left: 16em; */
}
.aboutKites .aboutCol4 .bsAboutImg4 {
    background-image: url("../images/featureImg1.svg");
    background-size: contain;
    background-position: left;
    height: 7em;
    background-repeat: no-repeat;
}
.aboutKites .arabicStyleAboutCol4 .bsAboutImg4 {
    background-image: url("../images/featureImg1.svg");
    background-size: contain;
    background-position: right;
    height: 7em;
    /* width: 6em; */
    background-repeat: no-repeat;
    /* margin-bottom: 2em; */
    /* margin-left: 27em; */
}
.aboutKites .aboutCol3 .bsAboutImg3 {
    background-image: url("../images/featureImg2.svg");
    background-size: contain;
    background-position: left;
    height: 7em;
    background-repeat: no-repeat;
}
.aboutKites .arabicStyleAboutCol3 .bsAboutImg3 {
    background-image: url("../images/featureImg2.svg");
    background-size: contain;
    background-position: right;
    /* width: 100%; */
    height: 7em;
    background-repeat: no-repeat;
    /* margin-bottom: 2em; */
    /* margin-left: 12em; */
}
.aboutKites .aboutCol6 .bsAboutImg6 {
    background-image: url("../images/featureImg1.svg");
    background-size: contain;
    background-position: left;
    height: 7em;
    background-repeat: no-repeat;
}
.aboutKites .arabicStyleAboutCol6 .bsAboutImg6 {
    background-image: url("../images/featureImg1.svg");
    background-size: contain;
    background-position: right;
    height: 7em;
    /* width: 8em; */
    background-repeat: no-repeat;
    /* margin-bottom: 2em; */
    /* margin-left: 21em; */
}
.aboutKites .aboutCol5 .bsAboutImg5 {
    background-image: url("../images/kitesLogo.png");
    background-size: contain;
    background-position: left;
    height: 7em;
    background-repeat: no-repeat;
    margin-bottom: 1em;
}
.aboutKites .arabicStyleAboutCol5 .bsAboutImg5 {
    background-image: url("../images/kitesLogo.png");
    background-size: contain;
    background-position: right;
    /* width: 80%; */
    height: 7em;
    background-repeat: no-repeat;
    margin-bottom: 1em;
    /* margin-left: 9em; */
}
.aboutKites .aboutCol1 .bsAboutHeader1 .bsAboutHeader1H1, .aboutKites .aboutCol2 .bsAboutHeader2 .bsAboutHeader2H1, .aboutKites .aboutCol3 .bsAboutHeader3 .bsAboutHeader3H1,
.aboutKites .aboutCol4 .bsAboutHeader4 .bsAboutHeader4H1, .aboutKites .aboutCol5 .bsAboutHeader5 .bsAboutHeader5H1, .aboutKites .aboutCol6 .bsAboutHeader6 .bsAboutHeader6H1 {
    color: #ffb345;
    font-size: 30px;
    font-family: 'Cairo';
    text-align: left;
    font-weight: bold;
    margin-bottom: 15px;
}
.aboutKites .aboutCol1 .bsAboutHeader1 .arabicStyleBsAboutHeader1H1, .aboutKites .aboutCol2 .bsAboutHeader2 .arabicStyleBsAboutHeader2H1, .aboutKites .aboutCol3 .bsAboutHeader3 .arabicStyleBsAboutHeader3H1,
.aboutKites .aboutCol4 .bsAboutHeader4 .arabicStyleBsAboutHeader4H1, .aboutKites .aboutCol5 .bsAboutHeader5 .arabicStyleBsAboutHeader5H1, .aboutKites .aboutCol6 .bsAboutHeader6 .arabicStyleBsAboutHeader6H1 {
    color: #ffb345;
    font-size: 30px;
    font-family: 'Cairo';
    text-align: right;
    font-weight: bold;
    margin-bottom: 15px;
}
.aboutKites .aboutCol1 .bsAboutP1 .bsAboutP1P, .aboutKites .aboutCol2 .bsAboutP2 .bsAboutP2P, .aboutKites .aboutCol3 .bsAboutP3 .bsAboutP3P,
.aboutKites .aboutCol4 .bsAboutP4 .bsAboutP4P, .aboutKites .aboutCol5 .bsAboutP5 .bsAboutP5P, .aboutKites .aboutCol6 .bsAboutP6 .bsAboutP6P
{
    color: #808080;
    font-size: 20px;
    font-family: 'Cairo';
    font-weight: 600;
    margin-bottom: 25px;
    text-align: left;
    direction: ltr;
}
.aboutKites .aboutCol5 .bsAboutP7 .bsAboutP7P {
    font-size: 20px;
    font-family: 'Cairo';
    font-weight: 600;
    margin-bottom: 25px;
    text-align: left;
    direction: rtl;
}
.aboutKites .aboutCol5 .bsAboutP7 .arabicStyleBsAboutP7P {
    font-size: 20px;
    font-family: 'Cairo';
    font-weight: 600;
    margin-bottom: 25px;
    text-align: right;
    direction: ltr;
}
.aboutKites .aboutCol1 .bsAboutP1 .arabicStyleBsAboutP1P, .aboutKites .aboutCol2 .bsAboutP2 .arabicStyleBsAboutP2P, .aboutKites .aboutCol3 .bsAboutP3 .arabicStyleBsAboutP3P,
.aboutKites .aboutCol4 .bsAboutP4 .arabicStyleBsAboutP4P, .aboutKites .aboutCol5 .bsAboutP5 .arabicStyleBsAboutP5P, .aboutKites .aboutCol6 .bsAboutP6 .arabicStyleBsAboutP6P
{
    color: #808080;
    font-size: 20px;
    font-family: 'Cairo';
    text-align: right;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: right;
    direction: rtl;
}
.aboutKites .aboutCol5 .bsAboutP7 .bsAboutP7Btn {
    padding: 15px;
    border: 2px solid #000000;
    border-radius: 10px;
    font-size: 15px;
    background-color: #ffb345;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Cairo';
    margin-left: 5em;
}
.aboutKites .aboutCol5 .bsAboutP7 .arabicStyleBsAboutP7Btn {
    padding: 15px;
    border: 2px solid #000000;
    border-radius: 10px;
    font-size: 15px;
    background-color: #ffb345;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Cairo';
    margin-right: 5em;
    margin-left: 0em;
}
.aboutKites .aboutCol5 .bsAboutP7 .bsAboutP7Btn:hover {
    background-color: #184b80;
    color: #ffb345;
}


.kitesEnd { 
    background-color: #11438a;
    font-family: 'Cairo';
}
.kitesEnd .besallaImgsCol .bsImgTwo {
    background-image: url("../images/besallaMob.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 28em;
    background-repeat: no-repeat;
}
.kitesEnd .besallaTextCol {
    text-align: left;
    padding: 8em 0em 0em 8em;
}
.kitesEnd .arabicStyleBesallaTextCol2 {
    text-align: right;
    padding: 8em 8em 0em 0px;
}
.kitesEnd .besallaTextCol .besallaText h1 {
    color: #ffb345;
    font-size: 29px;
    font-family: 'Cairo';
    text-align: left;
    font-weight: 600;
    margin-bottom: 1em;
    direction: ltr;
    padding-right: 1em;
}
.kitesEnd .arabicStyleBesallaTextCol2 .besallaText h1 {
    color: #ffb345;
    font-size: 29px;
    font-family: 'Cairo';
    text-align: right;
    font-weight: 600;
    margin-bottom: 1em;
    direction: rtl;
    padding-left: 1em;
    padding-right: 0em;
}
.kitesEnd .besallaTextCol .besallaText p {
    color: #ffffff;
    font-size: 20px;
    font-family: 'Cairo';
    text-align: right;
    font-weight: 600;
    margin-bottom: 2em;
}
.kitesEnd .besallaTextCol .store .AppleStore {
    width: 40%;
}
.kitesEnd .besallaTextCol .store .appStoreLogo {
    width: 40%;
    margin-left: 10px;
}
.kitesEnd .arabicStyleBesallaTextCol2 .store .appStoreLogo {
    width: 40%;
    margin-right: 10px;
}

@media screen and (max-width: 1400px){
    .products-container .BdaitBluLogo {     top: 5em;    }
    .kites .besallaTextCol { padding: 10em 3em 4em 2em; }
    .kites .besallaTextCol .besallaLogo {
        height: 6em;
        margin-left: 0em;
        margin-bottom: 2em;
    }
    /* .aboutKites .aboutCol5 {     margin-left: 11em;    } */
    /* .kitesEnd .besallaTextCol .besallaText .besallaLogo {
        margin-right: 0em;
        height: 7em;
    } */
    /* .kitesEnd .besallaTextCol .besallaText .WhatsappYellowLogo { height: 7em; } */
    .aboutKites .aboutColRes { padding: 0em 10em 5em 0em; }
    .aboutKites .arabicStyleAboutColRes { padding: 0em 0em 5em 10em; }
}
@media screen and (max-width: 992px){
    .aboutKites .aboutCol1 { padding: 3em 5em 5em 6em; }
    .aboutKites .aboutCol2 { padding: 3em 5em 5em 5em; }
    .aboutKites .aboutCol3 { padding: 0em 5em 5em 6em; }
    .aboutKites .aboutCol4 { padding: 0em 5em 5em 5em; }
    .aboutKites .aboutCol5 {
        margin-left: 0em;
        width: 100%;
    }
    .aboutKites .aboutCol6 { padding: 5em 5em 5em 6em; }
    .aboutKites .aboutColRes { padding: 0em 5em 5em 25em; }
    .aboutKites .besallaTextCol .besallaLogo { margin-right: 4em; }
    /* .aboutKites .besallaTextCol .besallaText { margin-right: 4em; } */
    .aboutKites .kitesRow { padding: 2em 5em 0em 2em; }
}
@media screen and (max-width: 951px){

}
@media screen and (max-width: 895px){

}
@media screen and (max-width: 767px){
    .kites .besallaImgsCol .bsImgTwo { left: 0em; }
    .kites .besallaImgsCol .bsImgOne { margin-left: -135px; }
    .kites .besallaTextCol { padding: 5em 3em 4em 2em; }
    .kitesEnd .besallaTextCol { padding: 5em 5em 4em 0em; }
    .aboutKites .besallaImgCol .besallaLogo {
        background: unset;
        height: unset;
    }
    .aboutKites .kitesRow { padding: 2em 1em 0em 2em; }
    .kites .besallaTextCol .besallaText .besallaTextInput { width: 80%;}
}
@media screen and (max-width: 540px){
    .aboutKites .aboutColRes { padding: 0em 5em 5em 3em; }
}
@media screen and (max-width: 480px){
    .aboutKites .besallaTextCol .besallaLogo { margin-right: 1em; }
    .aboutKites .besallaTextCol .besallaText { margin-right: 1em; }
    .aboutKites .aboutCol1 { padding: 3em 2em 5em 1em; }
    .aboutKites .aboutCol2 { padding: 3em 2em 5em 1em; }
    .aboutKites .aboutCol3 { padding: 0em 2em 5em 2em; }
    .aboutKites .aboutCol4 { padding: 0em 2em 5em 2em; }
    .aboutKites .aboutCol5 { padding: 1em 1em 0em 1em; }
    .aboutKites .aboutColRes { padding: 0em 2em 5em 1em; }
    .aboutKites .aboutCol6 { padding: 5em 2em 2em 2em; }
    .aboutKites .aboutCol5 .bsAboutHeader5 h1 { font-size: 30px; }
    .aboutKites .aboutCol5 .bsAboutP5 p, .aboutKites .aboutCol5 .bsAboutP7 p{ font-size: 18px; }
    .aboutKites .aboutCol5 .bsAboutP7 button { 
        font-size: 15px;
        margin-right: 5em;
    }
    .kitesEnd .besallaTextCol { padding: 5em 2em 4em 2em; }
    .aboutKites .kitesRow { padding: 2em 1em 0em 2em; }
    .aboutKites .besallaTextCol .besallaText h1 {font-size: 40px; }
}
@media screen and (max-width: 400px) {
    .aboutKites .aboutCol5 .bsAboutHeader5 h1 { font-size: 30px; }
    .aboutKites .aboutCol5 .bsAboutP5 p, .aboutKites .aboutCol5 .bsAboutP7 p{ font-size: 15px; }
    .aboutKites .aboutCol5 .bsAboutP7 button { 
        font-size: 12px;
        margin-right: 4em;
    }
}