html {
  scroll-behavior: smooth;
}

.loaderDiv {
  justify-content: center;
  align-items: center;
  display: flex;
}
.topNavbar {
  display: flex;
  justify-content: space-between;
  padding: 1em 6vw;
  border-bottom: 1px solid #f2f4f7;
  align-items: center;
  position: fixed;
  z-index: 20;
  top: 0;
  width: 100%;
  background: white;
}

#services {
  padding-bottom: 5vw !important;
}
.feedBackForm {
  padding: 40px 48px !important;
  border-radius: 12px;
  border: 1px solid #e4e7ec;
  background: #fff !important;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.feedBackForm #tryFeedback {
  margin-bottom: 20px;
}
.feedBackForm #tryText {
  margin-bottom: 48px;
}

.feedBackForm label {
  margin-top: 24px !important;
  margin-bottom: 8px !important;
}
.feedBackForm input {
  padding: 13px 1em !important;
}
.feedBackForm #submit {
  padding: 18px 1em !important;
}
.serviceForm input {
  font-size: 16px;
}
.img-dark {
  display: none;
}
.langauge-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 3em;
  text-align: start;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--White, #fff);
  min-width: 15vw;
  /* Shadow/lg */
  padding: 12px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
#lightBtn {
  margin-bottom: 10px;
}
.langauge-modal button {
  border: none;
  background-color: white;
  color: #101828;
  /* 14/Bold */
  border-radius: 6px;
  padding: 5px;
  text-align: start;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.langauge-modal button:hover {
  background-color: #3b6dea3e;
  color: #3b6dea;
}
#backHome {
  background-color: #3b6dea;
  color: white;
  border-radius: 5px;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  border: none;
  width: 80%;
  margin: auto;
  padding: 13px 0;
  margin-top: 1em;
  transition: 0.2s;
}
.successImg {
  height: 14vw;
  width: 14vw;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  margin-top: 12px;
  min-width: 130px;
  min-height: 130px;
}
#backHome:hover {
  background-color: #62c4f9;
}
.successModal {
  text-align: center;
}

.successModal .modal-content {
  padding: 0;
  background-color: white;
  border-radius: 0 !important;
}
.paddingText {
  padding: 20px;
  padding-top: 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.242);
}
.successJob {
  background-color: #f7f9fe;
  margin-bottom: 2em;
  padding: 1em 0;
}
.successJob div {
  height: 20vw;
  width: 20vw;
  max-width: 397px;
  max-height: 397px;
  min-width: 180px;
  min-height: 180px;
}
#successTitle {
  color: #1d2939;
  font-family: "Montserrat";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
}
#successTitle2 {
  color: #0f338c;
  font-family: "Montserrat";
  font-size: 40px;
  font-style: normal;
  margin-bottom: 0;
  line-height: 38px;
  font-weight: 600;
  margin-bottom: 0.3em;
}
#successTitle3 {
  color: #667085;
  margin-bottom: 0;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
#noResult {
  text-align: center;
  font-size: 22px;
}

.foodModal,
.modalOverlay {
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 21;
}

.modalOverlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: fixed !important ;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 1em;
  border-radius: 8px;

  z-index: 22;

  display: unset !important;
  /* width: 500px ; */
  width: 80%;
  height: 571px;
}

.homeAr .qoute1 {
  text-align: end;
}
.homeAr .qoute1 div {
  display: inline-block;
}
.btnsDiv a {
  text-decoration: none;
}
.topNavbar a {
  text-decoration: none;
}
#clients {
  scroll-margin-top: 85px;
  padding-top: 40px !important;
  padding-bottom: 20px !important;
  margin: 96px 0;
}

.logo {
  height: 3em;
  width: 7em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.links {
  display: flex;
  justify-content: space-between;
  background-color: #f7f9fe;
  padding: 1em;
  border-radius: 10px;
  align-items: center;
  margin: auto;
}

#nav-dropdown {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin: 0 1.5em;
  color: #ffffff;
  font-family: "Cairo";
}
#nav-dropdown:hover {
  color: #3164e7;
}
/* #nav-dropdown:active{
  color: #3164E7 !important;
} */
.active-dropdown .nav-link {
  color: #3164e7 !important;
}
.links .dropdown-item .besallaNav,
.links .dropdown-item .kitesNav {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin: 0 1.5em;
  color: #000000;
  font-family: "Cairo";
}
.links .dropdown-item .besallaNav:hover,
.links .dropdown-item .kitesNav:hover {
  color: #3164e7;
}
.active .besallaNav,
.active .kitesNav {
  color: #ffffff !important; /* Your desired color */
}

.links p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin: 0 1.5em;
  color: #1f2d56;
  margin-bottom: 0;
}
.links p:hover {
  color: #3164e7;
}
.active p {
  color: #3164e7;
}
.request {
  background: #3b6dea;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: #ffffff;
  transition: 0.2s;
  padding: 0.7em 16px;
  margin-left: 5px;
}

.request:hover {
  background-color: #62c4f9;
}
.about {
  border: none;
  background: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin: 0 1.5em;
  color: #1f2d56;
  margin-bottom: 0;

  cursor: pointer;
  display: flex;
  align-items: center;
}
.about svg {
  margin: 0 0.3em;
}
.about:hover {
  color: #3164e7;
}
.firstDiv {
  padding: 0 6vw;
}
.firstDiv {
  padding-right: 0;
}
.homeAr .firstDiv {
  padding-left: 0;
  padding-right: 6vw;
}
.firstDiv button {
  padding: 14.5px 16px;
}
.learnMore {
  padding: 13px 25px !important;
}
#turnIdeas {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  max-width: 800px;
  color: #000000;
}
#turnIdeas span {
  background: #e7eefa;
  border-radius: 6px;
  display: inline-flex;
  line-height: 60px;
  padding: 0 5px;
}
.blackBck {
  display: none;
}
.homeAr #turnIdeas span {
  line-height: 90px;
}
#powerful {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #475467;
  max-width: 560px;
}

.learnMore {
  border: 1px solid #3b6dea;
  color: #3b6dea;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-left: 2em;
  padding: 0.7em 2em;
}
.learnMore svg {
  position: relative;
  transition: transform 0.8s;
}
.learnMore:hover {
  background-color: #3b6dea;
  color: white;
}
.learnMore:hover svg {
  transform: translate(10px, 0);
}
.socialBtns a {
  border: none;
  background-color: transparent;
  color: #ffffff !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1d2939;
  display: flex;
  padding: 0;
  align-items: center;
  text-decoration: none;
}
.nopadding {
  padding: 0 !important;
}
.socialBtns img {
  margin-right: 0.4em;
  width: 24px;
  height: 24px;
}

.socialBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 3em;
  max-width: 450px;
}
.socialBtns div {
  text-align: center;
}
.socialBtns a {
  display: inline-block;
}
.btnsDiv {
  margin-top: 2em;
}
.manImg {
  height: 55vw;
  width: 55vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  right: 8vw;
  bottom: 0;
  z-index: -1;
  max-height: 700px;
  max-width: 700px;
}

.homeAr .manImg {
  left: 10vw;
  right: auto !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.accent {
  height: 5vw;
  width: 5vw;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 32vw;
  bottom: 48vw;
  max-width: 63px;
  max-height: 63px;
}

.homeAr .accent {
  left: 32vw;
  right: auto !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.uploadInp {
  position: relative;
  cursor: pointer;
}

input::file-selector-button {
  display: none;
}
.uploadInp input {
  cursor: pointer;
  width: 100%;
}

.uploadInp span {
  width: 100%;
}
.uploadInp svg {
  position: absolute;
  left: 0.8em;
  bottom: 0.6em;
  cursor: pointer;
  pointer-events: none;
}
.uploadInpEn svg {
  left: auto;
  right: 0.8em;
}
.review {
  height: 5vw;
  width: 20vw;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.849);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 28vw;
  border-radius: 11px;
  bottom: 37vw;
  z-index: 10;
  animation-name: MoveUpDown;
  animation-duration: 7s;
  animation-iteration-count: infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 30vw;
  }
  50% {
    bottom: 37vw;
  }
}

.review2 {
  height: 5vw;
  width: 15.5vw;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.849);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 29vw;
  border-radius: 11px;
  bottom: 13vw;
  z-index: 10;
  animation-name: MoveLeftRight;
  animation-duration: 7s;
  animation-iteration-count: infinite;
}
.alignVert {
  display: flex;
  align-items: center;
}

@keyframes MoveLeftRight {
  0%,
  100% {
    right: 32vw;
  }
  50% {
    right: 29vw;
  }
}

#build {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
#everything {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #475467;
}
.secondDiv {
  margin-top: 2em;
  background-color: white;
  z-index: 11;
  padding: 0 6vw;
  padding-top: 5em;
}
.numbers {
  margin: 4em 0;
}
.numbers #number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;

  color: #101828;
  margin-bottom: 0;
}
.numbers #numberData {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  color: #667085;
}
.numbers img {
  float: left;
  margin-right: 1em;
  position: relative;
  top: 10px;
}

.borderDiv {
  /* 
    border: 1px solid  #EAECF0;
    border-style: none solid none solid;*/
}
.stats {
  text-align: center;
}
#alignText {
  display: inline-block;

  text-align: left;
}
#alignText div {
  display: inline-block;
}
.custom-border {
  position: relative;
}

.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 1px #eaecf0 solid;

  top: 10px;
  right: 0;
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
}

.custom-border:before {
  content: " ";
  position: absolute;

  border-right: 1px #eaecf0 solid;
  top: 10px;
  left: 0;
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
}
#ourTeam {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  color: #3b6dea;
}
#dynamic {
  /* 36/Bold */
  max-width: 550px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.02em;

  /* Gray/800 */

  color: #1d2939;
}
#dynamic2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* or 150% */

  /* Gray/500 */

  color: #667085;
}
.teamLi p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2em;
  color: #344054;
}

.thirdDiv {
  padding: 96px 4vw;
  position: relative;
  margin-top: 0;
}

.TeamDiv {
  padding-top: 2em !important;
}
.thirdDiv .titleRow {
  padding-bottom: 200px;
  padding-left: 2em;
}
.thirdDiv .teamRow {
  margin-left: 30px;
  /* margin: calc(-1 * var(--bs-gutter-y)) 0em 0px 1em; */
}
.thirdDiv .arabicStyle {
  margin-right: 30px;
  margin-left: 0;
}
.teamRow .cardCol {
  width: 24%;
  padding: 0;
  height: auto;
  margin: 3px;
  background-color: #262627;
  border: 1px #3b6dea solid;
}
.teamRow .specialCardCol {
  width: 24%;
  padding: 0;
  height: auto;
  margin: 3px;
  background-color: #3b6dea;
  border: 1px #3b6dea solid;
}
.teamRow .specialCardCol #card .specialCardTitle {
  font-family: Druk;
  color: #ffffff;
  margin: 0;
  position: absolute;
  top: -310px;
  padding: 0px 1vw;
}
.teamRow .specialCardCol #card .specialCardTitle #specialTitleOne {
  font-size: 12em;
  font-family: Druk !important;
  margin: 0;
  height: 1.1em;
}
.teamRow .specialCardCol #card .specialCardTitle #specialTitleTwo {
  font-size: 6em;
  font-family: Druk !important;
  margin: 0;
}
#card {
  position: relative;
}
.teamRow .col-sm-6 .cardImg {
  height: 25em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  position: absolute;
  top: -265px;
  z-index: 0;
}

#card .cardLogo {
  height: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: 90%;
  margin: auto;
}
#card .cardLogoMob {
  display: none;
}
.teamRow .col-sm-6 .cardInfo {
  margin-top: 165px;
  padding: 20px;
  font-size: 1em;
  position: relative;
  z-index: 1;
  height: auto;
}
.teamRow .col-sm-6 .cardInfo p {
  color: white;
  margin-top: 3em;
  font-weight: 200;
  min-height: 20em;
}
.teamRow .col-sm-6 .cardInfo .arabicStyle {
  color: white;
  margin-top: 3em;
  font-weight: 200;
  min-height: 18em;
  margin-right: auto;
}
#card .cardInfo span {
  font-weight: 900;
}

#ourTeam span {
  border-bottom: 2.5px solid #3b6dea;
  width: 30px;
  position: relative;
  bottom: 10px;
  border-radius: 2px;
  margin-left: 5px;
}
.teamLi svg {
  color: #62c4f9;

  margin-right: 0.5em;
}
.more {
  background: #3b6dea;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: #ffffff;
  transition: 0.7s;
  padding: 0.7em 1em;
}
.more:hover {
  background-color: #62c4f9;
}
.thirdDiv #triangle {
  min-height: 400px;
  min-width: 400px;
  height: 50vw;
  width: 50vw;
  position: relative;

  background-repeat: no-repeat;
  background-size: contain;

  left: -6vw;
  border-radius: 11px;
}

#thirdImg {
  min-width: 280px;
  min-height: 280px;

  height: 40vw;
  width: 40vw;

  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 5vw;
  border-radius: 11px;
  top: 4vw;
  /* animation-name: UpDownImg;
    animation-duration: 10s;
    animation-iteration-count: infinite; */
}
#accent2 {
  height: 5vw;
  width: 5vw;

  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 43.5vw;
  border-radius: 11px;
  top: -1vw;

  animation-name: UpDownSvg;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.socialLinks {
  color: #d0d5dd;
}
.socialLinks a {
  margin: 0 0.5em;
  color: #d0d5dd;
}
.vertAlign {
  text-align: left;
  display: flex;
  /* align-items: center; */
  margin-top: 0;
}
@keyframes UpDownImg {
  0%,
  100% {
    top: 13vw;
  }
  50% {
    top: 3vw;
  }
}
@keyframes UpDownSvg {
  0%,
  100% {
    top: 9vw;
  }
  50% {
    top: -1vw;
  }
}

/*fourth div*/

.fourthDiv {
  background: #f9fafb;
  padding: 96px 6vw;
}
.homeServices {
  padding-bottom: 40px !important;
}
#doesMore {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 66px;
  line-height: 80px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  color: #101828;
}
#doesMore2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  max-width: 47em;
  color: #475467;
  margin-bottom: 3em;
  margin-top: 2em;
}
.squares {
  display: inline-block;
}
.homeServices {
  text-align: end;
}
.stepText-last {
  margin-bottom: 5vw !important;
}
.square {
  margin: auto;
  width: 20vw;
  cursor: pointer;

  max-width: 100%;
  transition: 0.5s;
  margin-bottom: 2em;
  padding: 24px;
  /* padding-bottom: 24px; */
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  margin-left: auto;
}
.squares1 {
  margin-right: 46px;
  position: relative;
  top: 4em;
}
.homeAr .squares1 {
  margin-left: 46px;

  margin-right: auto !important;
}
.service-container a {
  text-decoration: none !important;
}
.squares1 .square {
  position: relative !important;
}
.squareShadow {
  box-shadow: 0px 0px 24px rgba(15, 51, 140, 0.3);
}

.square:hover {
  box-shadow: 0px 0px 24px rgba(15, 51, 140, 0.3);
}
.service .arabicStyleSquareTitle {
  font-size: 27px !important;
  max-width: 9em !important;
  min-height: 3em;
}
#squareTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #101828;
  margin: 0.2em 0;
  max-width: 6em;
}
#squareData {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin-bottom: 0px;
}
.square img {
  margin-bottom: 1em;
}
.square {
  /* display: flex;
  align-items: center; */
}
.square button {
  border: none;
  padding: 0;
  background: transparent;
}
.squares a {
  text-decoration: none !important;
}
.square svg {
  color: #3b6dea;
  cursor: pointer;
  position: relative;
  transition: transform 0.8s;
}

.square:hover button svg {
  transform: translate(15px, 0);
}
.homeAr .square:hover button svg {
  transform: scaleX(-1) translate(15px, 0);
}

.square button:hover svg {
  transform: translate(15px, 0);
}

#process {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* margin-bottom: 0.5em; */
  text-align: center;
  color: #3b6dea;
}
#helpBusiness {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  display: block;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
.fifthDiv {
  padding: 96px 6vw;
  margin-top: 4em;
}
#step1 div {
  font-family: "Montserrat";
  font-style: normal;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
  background: #8eabf3;
  color: #0f338c;
}
#step1 {
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  height: 50px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.stepsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#line {
  width: 4px;
  height: 11vw;
  left: 638px;
  top: 39.03px;

  /* Primary/300 */

  background: #8eabf3;
}
#stepText1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #101828;
}
#fifthTitle {
  margin-bottom: 56px;
}
#stepText2 {
  max-width: 300px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #667085;
}
.stepText {
  margin: 9.5vw 0;
  z-index: 1;
  position: relative;
}

.stepImg {
  z-index: 1;
  position: relative;
  margin: 3em 0;
  background: #b7caf7;
  border-radius: 24px;
  padding: 24px;
  padding-bottom: 0;
  width: 96.5%;
}
.stepImg div {
  border-radius: 20px 20px 0px 0px;
  height: 21.5vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

#plane {
  height: 50em;
  width: 57em;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -9em;
  left: 0vw;
  display: block !important;
  z-index: 0;
}
.homeAr #plane {
  transform: scaleX(-1);
  left: auto !important;
  right: 0;
}

.servicesDiv a {
  text-decoration: none !important;
}

.dark-container {
  position: relative;
}

.firstStepDiv {
  position: relative;
}
.sixthDiv {
  padding: 3em 6vw;
  background: #e1e9fc;
  position: relative;
  padding-bottom: 0;
}
#quotes {
  width: 98px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
}
#smile {
  width: 70px;
  height: 60px;
  margin: 0 25px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}
.alignSmile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.stats img {
  width: 60px;
  height: 60px;
}
.quotes2 {
  margin-left: auto;
  position: relative;
  bottom: 2em;
}
#clinetRev {
  width: 42vw;
  height: 17vw;
  background-size: cover;
  background-repeat: no-repeat;
}
#cliendFeedback {
  width: 42vw;

  padding: 1em;
  border-radius: 8px;
  background-color: white;
}

.swiper-button-next,
.swiper-button-prev {
  top: 85% !important;
}

.swiper-button-prev {
  background-image: url("../images/left.svg") !important;
  background-repeat: no-repeat;
  background-color: white;

  background-position: center;
  border-radius: 50%;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  width: 4vw !important;
  height: 4vw !important;
  min-width: 30px;
  min-height: 30px;
  max-width: 50px;
  max-height: 50px;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  width: 4vw !important;
  height: 4vw !important;
  min-width: 30px;
  min-height: 30px;
  max-width: 50px;
  max-height: 50px;
}
.swiper-button-prev:hover {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}

.swiper-button-next {
  background-image: url("../images/right.svg") !important;

  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  border-radius: 50%;
}
.swiper-button-next:hover {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}
.seventhDiv {
  padding: 96px 6vw;
}
.client {
  border: 1px solid #e4e7ec;
  border-radius: 16px;
  padding: 21px;
}
.clientImg {
  min-height: 64px;
  min-width: 80px;
  height: 5vw;
  margin: auto;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.eightthDiv {
  padding: 96px 6vw;
  background: #f9fafb;
  position: relative;
}
.spaceBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.strech {
  display: flex;
  align-items: stretch;
}
.aboutUs-container .strech {
  justify-content: center;
}
#quotReach {
  text-align: start;
}
.projectDiv {
  background: #ffffff;
  transition: 0.5s;
  border-radius: 8px;
  border: 1px solid rgb(242, 239, 239);
  height: 100%;
  position: relative;
}
.projectDiv:hover {
  box-shadow: 0px 0px 24px rgba(15, 51, 140, 0.3);
  cursor: pointer;
}
.contDiv-margin {
  margin: 0 5vw;
}
.projImg {
  background: #fae3cc;
  padding: 10px 10px;
  border-radius: 8px 8px 0 0;
}
.projImg div {
  height: 15vw;

  margin: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.projData {
  padding: 24px;
  min-height: 235px;
}
#projName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #101828;
}
#viewProj {
  padding-bottom: 24px !important;
}
#projDetail {
  /* 16/Regular */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 51px;
  color: #475467;
}
#viewProj {
  background-color: white;
  border: 1px solid rgb(242, 239, 239);
  width: 100%;
  border-style: solid none none none;
  padding: 10px 0 !important;
  border-radius: 0 0 8px 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #3b6dea;
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
}

#viewProj svg {
  transition: transform 0.8s;
  position: relative;
}
#viewProj:hover svg {
  transform: translate(10px, 0);
}
#viewProj:hover {
  color: white;
  background-color: #3b6dea;
  border: 1px solid #3b6dea;
}
.projType {
  display: flex;
  align-items: center;
  color: #5925dc;
  background: #f4f3ff;
  mix-blend-mode: normal;
  border-radius: 16px;
  padding: 2px 8px;
  width: fit-content;
  margin-bottom: 12px;
}
#type1 p {
  color: #175cd3 !important;
}
#type1 svg {
  color: #3b6dea !important;
}
#type1 {
  background: #f7f9fe;
}
#type3 p {
  color: #b93815 !important;
}
#type3 svg {
  color: #ef6820 !important;
}
#type3 {
  background: #fff4ed;
}

.projType p {
  margin-bottom: 0;
  margin-left: 0.5em;
}
.ninethDiv {
  height: 12em;
  position: relative;
}
#reqQuestion {
  background: #0f338c;
  position: absolute;
  width: 85%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 5em;
  /* Shadow/xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
}
#question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: start;
  color: #ffffff;
}
#begin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  text-align: start;
  font-size: 20px;
  color: #e1e9fc;
}
#triangles {
  width: 43vw;
  height: 12vw;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0vw;
  right: -3vw;
  opacity: 0.3;
}
#questionBtn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #3b6dea;
  background-color: white;
  border: none;
  transition: 0.5s;
  padding: 0.7em 2em;
  border-radius: 5px;
  z-index: 10;
  position: relative;
}

#questionBtn:hover {
  background-color: #62c4f9;
}
.active p {
  color: #3b6dea !important;
}
#aboutNav svg {
  color: #d0d5dd;
  height: 16px;
  width: 16px;
}
#aboutNav a svg {
  height: 20px;
  width: 20px;
}

.footer {
  padding-top: 160px;
  background: #101828;
}
.footerTxt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 25px;
  max-width: 300px;
  color: #d0d5dd;
}
.logoW {
  width: 10vw;
  height: 10vw;
}
.siteMap a {
  text-decoration: none;
}
.siteMap p {
  word-break: break-all;
  font-family: "Montserrat";
  font-style: normal;

  font-size: 16px;

  text-transform: uppercase;

  /* Gray/25 */

  color: #fcfcfd;
}
.siteMap svg {
  color: #fcfcfd;
  margin-right: 0.5em;
}
.siteMap p:hover {
  color: #3b6dea;
}
.socialLinks svg:hover {
  opacity: 0.5;
}
#sitesTitle:hover {
  color: white !important;
}
.contacts p {
  text-transform: none !important;
}
.siteMap #sitesTitle {
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

.cr {
  background: #010f16;
  display: flex;
  justify-content: space-between;
  padding: 10px 6vw;
  color: #d0d5dd;
  align-items: center;
}
.cr p {
  display: inline-block;
  margin-bottom: 0;
}
.buildDiv {
  padding-top: 32px;
}
.besallaDiv {
  padding-top: 32px;
}
.smouhaDiv {
  padding-top: 32px;
}
.buildMain {
  /* background: #0B2463; */
  background: #262627;
  border: 2px solid #074272;
  /* Shadow/xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  /* border-radius: 24px; */
}
.besallaMain {
  /* background: #0B2463; */
  background: #262627;
  border: 2px solid #074272;
  /* Shadow/xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  /* border-radius: 24px; */
}
.smouhaMain {
  /* background: #0B2463; */
  background: #262627;
  border: 2px solid #074272;
  /* Shadow/xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  /* border-radius: 24px; */
  border-top-left-radius: 24px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 24px;
}
.arabicStyleSmouhaMain {
  border-top-left-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}
#buildTitle {
  max-width: 520px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  color: white;
  line-height: 132%;
}
.homeAr #buildTitle {
  max-width: 500px !important;
}

#buildTitle span {
  color: #2680ea;
}
#buildTitle .span2 {
  color: #1bd9e0;
}
#besallaTitle {
  max-width: 520px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  color: white;
  line-height: 132%;
}
#smouhaTitle {
  max-width: 600px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  color: white;
  line-height: 132%;
}
.homeAr #besallaTitle {
  max-width: 500px !important;
}

#besallaTitle span {
  color: #2680ea;
}
#besallaTitle .span2 {
  color: #1bd9e0;
}
.homeAr #smouhaTitle {
  max-width: 500px !important;
}

#smouhaTitle span {
  color: #2680ea;
}
#smouhaTitle .span2 {
  color: #1bd9e0;
}
#buildTxt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
}
#besallaTxt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 1em;
}
#smouhaTxt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 2em;
}
#learn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 150% */
  cursor: pointer;
  text-decoration-line: underline;

  /* Secondary/100 */
  margin-left: 1em;
  /* margin-bottom: 2em; */
  color: #1bd9e0;
}
#learn:hover {
  color: #2680ea;
}
#hire {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #0f338c;
  padding: 13px 2em;
  margin-right: 2em;
  transition: 0.7s;
}
#hire:hover {
  background-color: #e9f2f7;
}
#apply {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: transparent;
  color: #ffffff;
  padding: 13px 2em;
  transition: 0.7s;
}
#apply:hover {
  color: #0f338c;

  background-color: #e9f2f7;
}
.buildImg {
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.buildImg .buildImgOne {
  background-image: url("../images/astronauts.png");
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 27em;
  margin-top: -7em;
}
.buildImg .buildImgTwo {
  background-image: url("../images/squad.png");
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 8em;
  margin-top: -1em;
}
.homeAr .buildImg {
  border-radius: 24px 0 0 24px;
}
.besallaWhiteDiv {
  background-color: #ffffff;
  width: 40%;
  border-radius: 50px;
  padding: 30px 20px 100px 20px;
  margin-right: 0;
  margin-bottom: -110px;
}
.arabicStyleBesallaWhiteDiv {
  background-color: #ffffff;
  width: 40%;
  border-radius: 50px;
  padding: 30px 20px 100px 20px;
  margin-left: 0;
  margin-bottom: -110px;
  margin-right: auto;
}
.besallaWhiteDiv .besallaImgLogo {
  background-image: url("../images/besallsLogo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 7em;
}
.smouhaWhiteDiv {
  background-color: #ffffff;
  width: 40%;
  border-radius: 50px;
  padding: 20px 82px 90px 75px;
  margin-right: 0;
  margin-bottom: -110px;
  display: flex;
  align-items: center;
  gap: 5%;
}
.arabicStyleSmouhaWhiteDiv {
  background-color: #ffffff;
  width: 40%;
  border-radius: 50px;
  padding: 20px 90px 90px 80px;
  margin-left: 0;
  margin-bottom: -110px;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 5%;
}
.smouhaWhiteDiv .smouhaImgLogo {
  background-image: url("../images/smouhaLogo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 50px;
  height: 50px;
}
.smouhaWhiteDiv .arabicStyleSmouhaImgLogo {
  background-image: url("../images/smouhaLogo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 50px;
  height: 50px;
}
.smouhaWhiteDiv .smouhaWhiteDivTitle p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0;
}
.besallaImg {
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.besallaImg .besallaImgOne {
  background-image: url("../images/besallaMob.png");
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 40em;
  margin-top: -7em;
}
.besallaImg .arabicStyleBesallaImgsOne {
  margin-top: -10em;
}
.smouhaImg {
  padding-left: 0;
  padding-right: 0;
}
.smouhaImg .smouhaImgOne {
  background-image: url("../images/smouhaMobLeft.png");
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  width: 690px;
  height: 35em;
  margin-top: -7em;
}
.smouhaImg .arabicStyleSmouhaImgsOne {
  margin-top: -10em;
  background-image: url("../images/smouhaMob.png");
  background-position: right;
}
.besallaImg .besallaImgTwo {
  background-image: url("../images/besallaAppLogo.png");
  border-radius: 0 24px 24px 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 7em;
  margin-top: -14em;
}
.homeAr .besallaImg {
  border-radius: 24px 0 0 24px;
}
.toggOpen select {
  margin: 0 !important;
  margin-bottom: 7px !important;
}
.buildTri {
  position: absolute;
  right: -165px;
  height: 213px;
  width: 230px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
}
.toggleBtn {
  display: none;
}
#navbarMargin {
  height: 5.2em;
}
.mobileLinks {
  padding: 3vw;
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
}
.less {
  display: none;
}

#toTop {
  color: white;
  background: #101828;
  opacity: 0.4;
  border: none;
  border-radius: 50%;
  padding: 0.5em;
  position: fixed;
  bottom: 12em;
  right: 1em;
  z-index: 20;
  transform: scale(0);
}

.showUp {
  transition: all 0.7s ease-in-out;
  transform: scale(1) !important;
}
.hideUp {
  transform: scale(1.1);
  transition: all 0.7s ease-in-out;
}
#toTop:hover {
  opacity: 0.7;
}
.moreClients {
  margin-top: 1em;
  display: flex;
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.mobileLinks a {
  text-decoration: none;
}
.mobileLinks p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #1f2d56;
  margin-bottom: 0.5em;
  padding: 0.2em 0.5em;
}
.mobileLinks p:hover {
  color: #3164e7;
  background: #0238da2b;
}
.mobileLinks .about {
  margin: 0 !important;
  padding: 0;
}
.mobileLinks .active p {
  color: #3164e7;
  background: #0238da2b;
}
.mobileLinks .request {
  margin-top: 1em;
}
.toggClose {
  display: none;
}

.toggOpen {
  display: block;
  position: fixed;
  background-color: white;
  padding-bottom: 1em;
  width: 100%;
  z-index: 12;
}

.marginTopFirst {
  margin-top: 64px;
}

.trianglePlacement {
  padding: 53px 3vw;
}

#planeMobile {
  display: none;
}
.seventhDiv button {
  margin-top: 15px;
}
#feedbackHeader {
  text-align: center;
  background-color: #f9fafb;
  margin-bottom: 80px;
  padding: 5.6vw;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}
#feedbackHeader .logo {
  margin: auto;

  height: 5.6vw;
  width: 14vw;
}
#tryFeedback {
  color: var(--primary-700, #0f338c);

  font-size: 36px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
}
#tryText {
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.feedBackForm textarea {
  height: 8em;
}
#uploadImg {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  background: #ffffff;
  /* Gray/300 */

  padding: 1em;
  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
}
#uploadImg img {
  max-height: 25vh;
}
.feedbackSwiper .swiper-wrapper {
  padding-bottom: 7em;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#uploadedImg {
  position: relative;
  width: fit-content;
  margin: auto;
}
#uploadedImg button {
  position: absolute;
  right: -13px;
  top: -10px;
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.14);
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
#uploadIcn {
  background: #f2f4f753;
  border-radius: 100%;
  display: inline-block;
  padding: 8px 7px;
  margin-bottom: 0.5em;
}
#uploadIcn div {
  background-color: #f2f4f7;
  padding: 3px 7px;
  border-radius: 100%;
}
#uploadImg p {
  color: #475467;
  text-align: center;
  /* 14/Regular */
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
#uploadImg span {
  color: var(--primary-700, #0f338c);
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
}
#uploadImg input {
  position: absolute;
  z-index: -1;
  height: 0;
  width: 0;
}

.form-switch .form-check-input:checked {
  background-repeat: no-repeat;
  width: 64px !important;
  height: 24px !important;
  background-color: #039855;
  border: none !important;
  box-shadow: inset 0px 0px 0px 0px red;
  cursor: pointer;
}
#myBtn {
  color: #3b6dea;
  border: none;
  padding: 0 2px;
  background-color: transparent;
  /* 18/16/Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.form-switch .form-check-input {
  cursor: pointer;
  background-repeat: no-repeat;
  width: 64px !important;
  height: 24px !important;
  background-color: #e4e7ec;
  border-color: #e4e7ec;
  box-shadow: inset 0px 0px 0px 0px red;
}

.form-switch .form-check-input:focus {
  box-shadow: inset 0px 0px 0px 0px red !important;
  border-color: #e4e7ec;
}
#feedDescribe {
  color: var(--gray-600, #475467);
  margin-bottom: 0.8em;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-left: 2px solid #bce6fd;
  padding-left: 1em;
  min-height: 37px;
}
#feedImg {
  width: 55px;
  height: 55px;
  background-size: cover;
  border-radius: 100%;
  margin-bottom: 0.5em;
}
#quoteSvg {
  position: absolute;
  padding: 0 5px;
  background-color: white;
  top: -5px;
  right: -7px;
  border-radius: 100%;
  border: 1px solid #8080802b;
}
#feedName {
  color: var(--gray-900, #101828);

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}
#feedTitle {
  color: var(--gray-500, #667085);

  font-family: "Montserrat";
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}

.TechDiv {
  padding-bottom: 2em !important;
  padding-top: 0em !important;
}
.TechDiv .techHeader {
  padding: 0em 7em 0em 2em;
}
.TechDiv .arabicStyleTechHeader {
  padding: 0em 2em 0em 7em;
}
.TechDiv .techHeader h1 {
  font-family: "Cairo";
  font-weight: 700;
  font-size: 65px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.TechDiv .techHeader p {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 3em;
  color: #ffffff;
}
.TechDiv .TechRow {
  padding: 0em 5em 2em 5em;
}
.techCol1 .techOne .techOneImg {
  background-image: url("../images/PHPLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 5em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techTwoImg {
  background-image: url("../images/HTMlLLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 5em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techThreeImg {
  background-image: url("../images/AngularLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 5em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techFourImg {
  background-image: url("../images/AndroidLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 5em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techFiveImg {
  background-image: url("../images/SocketLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techSixImg {
  background-image: url("../images/ReactLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techSevenImg {
  background-image: url("../images/CSSLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techEightImg {
  background-image: url("../images/IOSLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techNineImg {
  background-image: url("../images/LaravelLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techTenImg {
  background-image: url("../images/AWSLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techElevenImg {
  background-image: url("../images/AgileLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techTwelveImg {
  background-image: url("../images/FlutterLogo.png");
  background-size: contain;
  background-position: left;
  min-height: 4em;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
.techCol1 .techOne .techOnetext span {
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
}
.techCol1 .techOne .techOnetext p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  max-width: 220px;
}

@media screen and (min-width: 1261px) {
  .manImg {
    right: 140px;
  }
  .homerAr .manImg {
    right: auto !important;

    left: 146px;
  }
  .accent {
    right: 33vw;
    bottom: 39em;
  }
  .homeAr .accent {
    right: auto !important ;
    left: 33vw;
    bottom: 39em;
  }
}

.servicesSlider {
  padding: 2em 0 0 3vw !important;
}
.homeAr .servicesSlider {
  padding: 2em 3vw 0 0 !important;
}

.mobileStep {
  display: none;
}
.viewAllMobile {
  display: none;
}

/* Ar Styles */

.homeAr .review {
  left: 28vw !important;
  right: auto !important;
}
.homeAr .review2 {
  right: auto !important;
  animation-name: MoveRightLeft;
}
@keyframes MoveRightLeft {
  0%,
  100% {
    left: 30vw;
  }
  50% {
    left: 34vw;
  }
}

.homeAr .learnMore svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.homeAr .learnMore:hover svg {
  transform: scaleX(-1) translate(10px, 0);
}
@media screen and (min-width: 1430px) {
  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 30vw;
    }
    50% {
      bottom: 35vw;
    }
  }

  .homeAr .review {
    left: 29vw !important;
  }
  .review {
    right: 29vw;

    max-width: 300px;
    max-height: 75px;
  }
  .review2 {
    max-width: 300px;
    max-height: 75px;
  }
  .accent {
    bottom: 620px;
  }
}

@media screen and (min-width: 1630px) {
  @keyframes MoveRightLeft {
    0%,
    100% {
      left: 25vw;
    }
    50% {
      left: 29vw;
    }
  }

  @keyframes MoveLeftRight {
    0%,
    100% {
      right: 25vw;
    }
    50% {
      right: 29vw;
    }
  }

  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 24vw;
    }
    50% {
      bottom: 27vw;
    }
  }

  .homeAr .review {
    left: 30vw !important;
  }
  .review {
    right: 30vw;
  }
  .manImg {
    left: 0 !important;
    right: 0 !important;
  }
  .accent {
    right: 27vw !important;
  }
  .homerAr .accent {
    right: auto !important;
    left: 27vw !important;
  }
}
.homeAr #alignText {
  text-align: right !important;
}
.homeAr .numbers img {
  float: right !important;
  margin-right: 0 !important;
  margin-left: 1em;
}

.homeAr .thirdDiv #triangle {
  right: -6vw;
  left: auto !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.homeAr #accent2 {
  left: auto !important;
  right: 43.5vw;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeAr #thirdImg {
  right: 5vw;
  left: auto !important;
}
.homeAr .teamLi svg {
  margin-right: 0 !important;
  margin-left: 0.5em;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeAr .buildTri {
  left: -165px;
  right: auto !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeAr #hire {
  margin-right: 0 !important;
  margin-left: 2em;
}
.homeAr .squares1 {
  margin-right: auto;
}
.homeAr .squares1 .square {
  margin-right: auto !important;
  margin-left: initial !important;
}

.homeAr .square svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeAr .square button:hover svg {
  transform: scaleX(-1) translate(15px, 0);
}
.homeAr .projType p {
  margin-left: 0;
  margin-right: 0.5em;
}
.homeAr #viewProj svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.homeAr #viewProj:hover svg {
  transform: scaleX(-1) translate(20px, 0);
}
.homeAr #triangles {
  left: -3vw;
  right: auto !important;
}
.homeAr .siteMap svg {
  margin-right: 0;
  margin-left: 0.5em;
}
.homeAr #aboutNav svg {
  transform: scaleX(-1);
}

.homeAr .careerType {
  margin-left: 0;
  margin-right: 1em;
}
.homeAr #careerNumb svg {
  margin-left: 0.3em;
  margin-right: 0;
}

.homeAr .jobDescribe li::before {
  margin-left: 0;
  margin-right: -1em;
}
.homeAr .services label {
  margin-right: 0.5em;
  margin-left: 0 !important;
}
.homeAr .containCheck input[type="checkbox"] {
  transform: translateY(0);
}
.homeAr .containCheck svg {
  right: -1px;
  left: auto !important;
}
.homeAr .quotationForm .nopadding {
  padding-right: initial !important;
  padding-left: 1em !important;
}
.homeAr .langauge-modal {
  right: auto;
  left: 0;
}
.client-padding {
  padding: 2px;
}
.homeAr .socialBtns img {
  margin-right: 0;
  margin-left: 0.4em !important;
}
.homeAr #dynamic,
.homeAr #doesMore,
.homeAr #doesMore2,
.homeAr .teamLi p,
.homeAr #dynamic2,
.homeAr #ourTeam {
  text-align: right;
}

@media screen and (min-width: 1335px) {
  .accent {
    /*
bottom: 450px;
right: 450px;*/
  }
  .homeAr .accent {
    /*
    left: 450px;
    right: auto*/
  }
}

.arNum {
  direction: ltr;
  flex-direction: row-reverse;
  display: flex;
}
.toggOpen select {
  display: block;
}
select {
  cursor: pointer;
}
.btnsAr .myService .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.btnsAr .myService .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
#clients .btnsAr .swiper-button-prev {
  left: calc(50% + 30px) !important;
  right: auto !important;
}

#clients .swiper-rtl .swiper-button-next {
  left: auto !important;

  right: 51% !important;
}

.swiper-button-next {
  left: calc(49% + 30px) !important;
  right: auto !important;
}

.swiper-button-prev {
  left: auto !important;

  right: 52% !important;
}

.homeAr #nice,
.homeAr #flexible,
.homeAr #aboutCompany,
.homeAr .vertAlign {
  text-align: start !important;
}
.homeAr .learnMore {
  margin-left: 0;
  margin-right: 2em;
}

@media screen and (max-width: 1400px) {
  .besallaWhiteDiv {
    width: 30%;
  }
  .besallaWhiteDiv .smouhaImgLogo {
    height: 5em;
  }
  .smouhaWhiteDiv {
    width: 30%;
    padding: 20px 30px 90px 17px;
  }
  .smouhaWhiteDiv .smouhaImgLogo {
    height: 5em;
    width: 45px;
  }
  .smouhaWhiteDiv .smouhaWhiteDivTitle p {
    font-size: 25px;
  }
  #besallaTitle {
    font-size: 60px;
  }
  #smouhaTitle {
    font-size: 50px;
  }
  #besallaTxt {
    font-size: 28px;
  }
  #smouhaTxt {
    font-size: 20px;
  }
  .besallaImg .besallaImgOne {
    height: 30em;
    margin-top: -92px;
  }
  .smouhaImg .smouhaImgOne {
    height: 30em;
    margin-top: -92px;
  }
  .besallaImg .besallaImgTwo {
    height: 6em;
    margin-top: -173px;
  }
  .smouhaWhiteDiv {
    width: 33%;
    padding: 15px 30px 90px 17px;
  }
}
@media screen and (max-width: 1269px) {
  #feedDescribe {
    min-height: 54.5px;
  }
}
@media screen and (max-width: 1260px) {
  .thirdDiv .teamRow {
    margin-right: 0px;
  }
  .thirdDiv .titleRow {
    padding-bottom: 12em;
  }
  .teamRow .col-sm-6 .cardImg {
    height: 17em;
  }
  .teamRow .col-sm-6 .cardInfo {
    padding: 8px;
    font-size: 0.9em;
  }
  .teamRow .col-sm-6 .cardInfo p {
    margin-top: 1em;
    min-height: 30em;
  }
  #card .cardLogo {
    height: 100px;
    background-position: right;
  }
  .teamRow .specialCardCol #card .specialCardTitle #specialTitleOne {
    font-size: 12em;
    margin-top: 0em !important;
  }
  .teamRow .specialCardCol #card .specialCardTitle #specialTitleTwo {
    font-size: 5em;
  }
  #reqQuestion {
    width: 95%;
  }
  .footer .container-fluid {
    padding: 3vw !important;
  }
  .eightthDiv {
    padding: 6vw 3vw;
  }
  .sixthDiv {
    padding: 3vw;
    padding-bottom: 0;
  }

  .topNavbar {
    padding: 1em 3vw;
  }
  .links p {
    margin: 0 1vw;
  }
  .about {
    margin: 0 1vw;
  }
  .thirdDiv {
    padding: 96px 3vw;
  }

  .secondDiv {
    margin-top: 0;
    padding: 0 3vw;
    padding-top: 4em;
  }
  #reqQuestion {
    padding: 0 3vw;
  }
  .firstDiv {
    padding: 0 3vw;
  }

  .seventhDiv {
    padding: 96px 3vw;
  }
  .fifthDiv {
    padding: 96px 3vw;
  }
}
@media screen and (max-width: 1216px) {
  .manImg {
    /* right: -68px;*/
  }
  .review {
    right: 27vw;
  }

  @keyframes MoveLeftRight {
    0%,
    100% {
      right: 23vw;
    }
    50% {
      right: 27vw;
    }
  }
  .homeAr .manImg {
    /* left: -68px;*/
  }
  .homeAr .review {
    left: 23vw;
  }
  @keyframes MoveRightLeft {
    0%,
    100% {
      left: 23vw;
    }
    50% {
      left: 27vw;
    }
  }

  .homeAr .accent {
    left: 31vw;
  }
}
@media screen and (max-width: 1143px) {
  .buildTri {
    right: -13vw;
    height: 16vw;
    width: 17vw;
  }
  #buildTitle {
    font-size: 5vw;
  }
  #buildTxt {
    font-size: 2.5vw;
  }
  #besallaTxt {
    font-size: 2.5vw;
  }
  #smouhaTxt {
    font-size: 2.5vw;
  }
  #hire {
    padding: 0.7em 1em;
  }
  #apply {
    padding: 0.7em 1em;
  }
  .smouhaImg .smouhaImgOne {
    height: 27em;
    margin-top: -75px;
  }
  #smouhaTitle {
    font-size: 40px;
  }
  #smouhaTxt {
    font-size: 20px;
  }
  .smouhaWhiteDiv .smouhaImgLogo {
    width: 35px;
  }
  .smouhaWhiteDiv .smouhaWhiteDivTitle p {
    font-size: 23px;
  }
  .homeAr .buildTri {
    left: -13vw;
    right: auto !important;
  }
}
/* @media screen and (min-width: 1056px) {
  .smouhaWhiteDiv {
    width: 33%;
    padding: 15px 30px 90px 17px;
  }
} */
@media screen and (max-width: 1049px) {
  #turnIdeas {
    font-size: 50px;
  }
  .thirdDiv #triangle {
    top: 2vw;
    position: absolute;
  }
  .homeAr #turnIdeas {
    line-height: 90px;
  }
}
@media screen and (min-width: 1016px) {
  .mobileLinks {
    display: none;
  }
}
@media screen and (max-width: 1015px) {
  .topNavbar .request,
  .topNavbar .links,
  #reqQuotation {
    display: none;
  }

  .toggleBtn {
    display: block;
    padding: 0.3em;
    background: #3b6dea;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 33px;
    cursor: pointer;
    border: none;
    transition: 0.7s;
  }
}
@media screen and (max-width: 991px) {
  .socialBtns {
    margin-bottom: 2em;
  }
  .firstDivImg {
    display: none;
  }
  .servicesDiv {
    margin-top: 2em;
    display: block;
  }
  .servicesDiv .square {
    width: 100%;
  }
  .homeServices {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .smouhaImg .smouhaImgOne {
    height: 29em;
    width: 100%;
    margin-top: -8em;
  }
  /* .smouhaWhiteDiv {
    width: 37%;
  } */
}
@media screen and (max-width: 931px) {
  #feedDescribe {
    min-height: 72.5px;
  }
}
@media screen and (max-width: 891px) {
  .thirdDiv {
    padding: 96px 3vw;
  }
  .thirdDiv .titleRow {
    padding-bottom: 12em;
    padding-left: 1em;
  }
  .thirdDiv .teamRow {
    margin-left: 0px;
  }
  /* #card .cardImg {    height: 275px;  } */
  /* #card .cardInfo {     
    padding: 5px;
    font-size: 14px;
    min-height: 446px;
  } */
  #card .cardLogo {
    height: 95px;
  }
  .teamRow .col-sm-6 {
    width: 49%;
    margin-bottom: 120px;
    border-radius: 10px;
  }
  .teamRow .col-sm-6 .cardImg {
    height: 35em !important;
  }
  .teamRow .col-sm-6 .cardInfo {
    padding: 8px;
    font-size: 1.4em !important;
  }
  .teamRow .col-sm-6 .cardInfo p {
    margin-top: 8em !important;
    /* text-align: left; */
    padding: 10px;
    min-height: 22em !important;
  }
  .teamRow .col-sm-6 .cardLogo {
    height: 95px;
  }
  .teamRow .specialCardCol #card .specialCardTitle {
    top: -310px;
    text-align: center;
    width: 100%;
    padding-left: 0px;
  }
  .teamRow .specialCardCol #card .specialCardTitle #specialTitleOne {
    font-size: 12em;
    margin-top: 0em !important;
  }
  .teamRow .specialCardCol #card .specialCardTitle #specialTitleTwo {
    font-size: 8em;
  }
  ç #smile {
    right: 0;
  }
  #fifthTitle {
    margin-bottom: 3em;
  }
  #clinetRev {
    width: 43vw;
    height: 18vw;
  }
  #cliendFeedback {
    width: 43vw;
  }
  .thirdDiv #triangle {
    bottom: 10vw;
  }
  #thirdImg {
    left: 3vw;
  }
  #accent2 {
    left: 41.5vw;
  }
  @keyframes UpDownImg {
    0%,
    100% {
      top: 18vw;
    }
    50% {
      top: 7vw;
    }
  }
  @keyframes UpDownSvg {
    0%,
    100% {
      top: 14vw;
    }
    50% {
      top: 3vw;
    }
  }
}
@media screen and (max-width: 857px) {
  .smouhaWhiteDiv {
    width: 42%;
  }
}
@media screen and (max-width: 802px) {
  .trianglePlacement {
    padding: 0;
    padding-top: 24px;
  }
  .numbers {
    margin: 2em 0;
  }
  .besallaImg .besallaImgOne {
    margin-top: -8em;
  }
  .smouhaImg .smouhaImgOne {
    margin-top: -8em;
  }
  .besallaImg .besallaImgTwo {
    margin-top: -8em;
  }
}
@media screen and (max-width: 767px) {
  .buildImg {
    border-radius: 0 0 24px 24px !important;
    padding-bottom: 30em;
  }
  .buildImg .buildImgTwo {
    margin-top: -1em;
  }
  .besallaImg {
    border-radius: 0 0 24px 24px !important;
    padding-bottom: 30em;
  }
  .besallaImg .besallaImgTwo {
    margin-top: -1em;
  }
  .buildCol2 {
    padding: 3vw 5vw 10vw 5vw !important;
  }
  .besallaCol2 {
    padding: 3vw 5vw 10vw 5vw !important;
  }
  .jobDet1 span {
    display: block;
    margin-top: 16px;
  }
  .jobDet1 {
    display: block !important;
  }
  #careerNumb svg {
    margin-left: 0 !important;
  }
  .homeAr #careerNumb svg {
    margin-right: 0 !important;
    margin-left: 8px !important;
  }
  .jobDet span {
    display: block;
    margin-top: 16px;
  }
  .jobDet span svg {
    margin-left: 0 !important ;
  }
  .homeAr .jobDet svg {
    margin-right: 0 !important ;
    margin-left: 8px !important ;
  }
  #jobDetail {
    max-width: 100% !important;
  }
  #jobDetail .buildImg {
    border-radius: 0 0 24px 24px !important;
  }
  .besallaImg {
    border-radius: 0 0 24px 24px !important;
  }
  .marginTopFirst {
    margin-top: 20%;
  }

  #clinetRev {
    margin: auto;
  }
  #cliendFeedback {
    margin: auto;
    width: 57vw;
  }
  .swiperHeight {
    height: 31vw !important;
  }
  #clinetRev {
    width: 57vw;
    height: 23vw;
  }

  #smile {
    display: none;
  }
  #fifthTitle {
    margin-bottom: 2em;
  }
  .stepImg {
    padding: 1em;
    padding-bottom: 0;
    width: 100%;
    margin: 1em 0;
    margin-bottom: 0 !important;
  }
  .stepText {
    margin: 3em 0;
  }

  #line {
    height: 108px;
  }
  #stepText1 {
    margin-bottom: 0.5em;
    line-height: 37px;
  }
  .stepImg div {
    border-radius: 20px 20px 7px 7px;
    height: 23vw;
  }
  #buildTitle {
    font-size: 38px;
    line-height: 44px;
  }
  #besallaTitle {
    font-size: 38px;
    line-height: 44px;
  }

  .buildMain {
    border-radius: 0;
  }
  .besallaMain {
    border-radius: 0;
  }
  .smouhaMain {
    border-radius: 0;
  }

  .buildImg {
    background-position: center;
    border-radius: 0;
    height: 43vh;
    width: 100%;
  }
  .besallaImg {
    background-position: center;
    border-radius: 0;
    height: 43vh;
    width: 100%;
  }
  .smouhaImg {
    background-position: center;
    border-radius: 0;
    height: 43vh;
    width: 100%;
  }
  .buildTri {
    display: none;
  }
  .jobDescribe {
    padding: 64px 3vw !important;
  }

  #thirdImg {
    animation-name: none;
    left: auto;
    right: 3vw;
    top: 16.5vw;
  }
  #accent2 {
    display: none;
  }
  .thirdDiv #triangle {
    position: relative;
    bottom: 0;
    top: -2vw;
    right: -7vw;
    left: auto;
    transform: scaleX(-1);
  }
  .thirdDiv {
    margin-top: 0;
  }
  .teamDecore {
    position: relative;
    display: flex;
    justify-content: end;
  }
  .vertAlign {
    margin-top: 1em;
  }
  .besallaImg .besallaImgOne {
    background-position: left;
  }
  .smouhaImg .smouhaImgOne {
    background-position: left;
    width: 463px;
    height: 30em;
  }
  .besallaImg .besallaImgTwo {
    background-position: left;
  }
  .besallaImg .arabicStyleBesallaImgsOne {
    background-position: right;
  }
  .smouhaImg .arabicStyleSmouhaImgsOne {
    background-position: right;
  }
  .besallaImg .arabicStyleBesallaImgsTwo {
    background-position: right;
  }
  #besallaTxt {
    margin-bottom: 2em;
  }
  #smouhaTxt {
    margin-bottom: 2em;
  }
  .smouhaWhiteDiv .smouhaImgLogo {
    width: 30px;
  }
  .smouhaWhiteDiv .smouhaWhiteDivTitle p {
    font-size: 17px;
  }
  .smouhaWhiteDiv {
    width: 40%;
    padding: 15px 20px 90px 10px;
  }
  .teamRow .col-sm-6 .cardInfo p {
    margin-top: 40%;
  }
}
@media screen and (max-width: 575px) {
  .thirdDiv .teamRow {
    margin-right: 5px !important;
  }
  .thirdDiv .titleRow {
    padding-bottom: 25em !important;
    padding-left: 0em !important;
  }
  .teamRow .col-sm-6 {
    width: 100% !important;
    padding: 10px 0 !important;
    border-radius: 15px !important;
    margin-bottom: 20em !important;
  }
  .teamRow .specialCardCol {
    width: 100% !important;
    padding: 10px 0 !important;
    border-radius: 15px;
  }
  .teamRow .col-sm-6 .cardImg {
    height: 40em !important;
  }
  .teamRow .col-sm-6 .cardInfo {
    padding: 30px !important;
    min-height: 158px !important;
    margin-top: 0px !important;
    font-size: 0.9em;
  }
  .teamRow .col-sm-6 .cardInfo p {
    margin-top: 13em;
    min-height: 15em !important;
  }
  #card .cardLogo {
    height: 150px;
  }
  .teamRow .specialCardCol #card .specialCardTitle {
    top: -145px !important;
    text-align: center;
    width: 100%;
    padding-left: 0px;
  }
  .teamRow .specialCardCol #card .specialCardTitle #specialTitleOne {
    font-size: 12em;
  }
  .teamRow .specialCardCol #card .specialCardTitle #specialTitleTwo {
    font-size: 5em;
  }
  .aboutUs-container #teamMember {
    padding-bottom: 2em !important;
  }
  #contact,
  #hear {
    margin-bottom: 8px !important;
  }
  #quotDiv .compImg {
    margin: 64px 0 !important;
    margin-top: 32px !important;
  }
  #quotDiv {
    padding: 64px 3vw !important;
  }
  #feedbackHeader .logo {
    min-width: 130px;
    min-height: 60px;
  }
  #tryFeedback {
    font-size: 28px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 12px !important;
  }
  .feedBackForm #tryText {
    text-align: center;

    font-size: 18px !important;
    line-height: 28px !important;
    margin-bottom: 32px !important;
  }
  #flexible {
    line-height: 38px !important;
    margin-top: 8px;
  }
  #aboutUspoint1 .compImg {
    margin: 64px 0 !important;
  }
  #about {
    font-size: 32px !important;
  }
  .aboutTxt {
    padding-top: 0 !important;
    padding-bottom: 64px !important;
  }
  .whyUs-about {
    margin-top: 64px !important;
  }
  .fi {
    width: 70px !important;
    height: 70px !important;
  }
  #history {
    font-size: 32px !important;
  }
  .our {
    padding: 48px 40px !important;
  }

  .whyUs .request {
    padding: 10px 16px !important;
  }
  .serviceForm {
    padding: 16px !important;
  }
  #jobName {
    line-height: 30px;
    margin-bottom: 24px !important;
    font-size: 28px;
  }
  .serviceForm #jobName {
    font-size: 24px !important;
  }
  .jobDescribeContainer {
    padding-bottom: 0 !important;
  }
  .projData #type1,
  #projName {
    margin-bottom: 8px !important;
  }
  .socialBtns {
    margin-top: 34px;
    flex-wrap: wrap;
  }
  .homeAr .socialBtns {
    flex-wrap: nowrap !important;
  }
  #hire {
    margin-right: 1em !important;
    padding: 0.7em 10px;
  }
  #toTop svg {
    height: 30px;
    width: 30px;
  }
  .homeAr #hire {
    margin-left: 1em !important;
    margin-right: auto !important;
  }
  #reqQuestion {
    top: 1.5em;
  }
  .buildImg {
    height: 32vh;
  }
  .besallaImg {
    height: 32vh;
  }
  .stepImg {
    padding: 20px;
  }
  .stepText {
    margin: 32px 0 !important;
  }
  .eightthDiv .container-fluid {
    margin-top: 42px !important;
  }
  .square {
    padding: 12px 24px;
  }
  .fourthDiv,
  .thirdDiv,
  .seventhDiv,
  .eightthDiv {
    padding: 32px 6vw !important;
  }
  .fifthDiv {
    padding: 32px 6vw !important;
  }
  #plane {
    display: none !important;
  }
  #reqQuestion {
    padding: 3vw;
  }
  .marginTopFirst {
    margin-top: 100px;
  }

  #successTitle {
    font-size: 25px;
    line-height: 20px;
    margin-bottom: 0.5em;
  }
  #successTitle2 {
    font-size: 25px;
    line-height: 20px;
    margin-bottom: 0.5em;
  }
  .successImg {
    margin: 0.5em auto;
  }
  .viewAllMobile {
    display: block;
  }
  .viewAll {
    display: none;
  }
  .request {
    font-size: 13px;
  }
  #clients {
    padding: 6vw 3vw;
  }
  .swiperHeight {
    height: 50vw !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 33vw !important;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 33vw !important;
  }
  #clinetRev {
    height: 40vw;
    min-width: 100%;
  }
  #cliendFeedback {
    min-width: 100%;
  }
  #quotes {
    display: none;
  }
  #line {
    height: calc(25vw + 62px);
  }
  .home-container .footer {
    padding-top: 132px;
  }
  .eightthDiv .col-lg-4 {
    padding: 0 !important;
  }
  .homeAr #line {
    height: calc(25vw + 54px);
  }
  .stepImg div {
    height: 42vw;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-size: 14px;
  }
  #fifthTitle {
    margin-bottom: 2em;
  }
  .stepText {
    margin-top: 1em;
  }
  #stepText1 {
    font-size: 20px;
    margin-bottom: 0;
  }
  #stepText2 {
    font-size: 14px;
  }
  .stepImg {
    margin: 0 !important;
    padding-bottom: 0;
  }
  .stepText {
    display: none;
  }
  .mobileStep {
    display: block !important;
  }

  .firstStepDiv {
    display: none;
  }
  #process {
    font-size: 14px;
    line-height: 20px;
  }
  #helpBusiness {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 0;
  }

  .fifthDiv {
    margin-top: 0;
  }
  #doesMore2 {
    margin-bottom: 1em;
  }
  .more {
    font-size: 13px;
  }
  #doesMore {
    font-size: 32px;
    line-height: 38px;
  }
  .fourthDiv {
    background: white;
  }
  #hire {
    font-size: 13px;
  }
  .projImage {
    min-height: 150px;
  }
  #aboutUspoint1 .container-fluid {
    display: flex;
    justify-content: center;
  }
  .servicesSlider {
    padding: 0 3vw !important;
  }
  .homeAr .servicesSlider {
    padding: 0 3vw !important;
  }
  #apply {
    font-size: 13px;
    margin-top: 10px;
  }
  #buildTitle {
    font-size: 36px;
    line-height: 44px;
  }
  besallaTitle #besallaTitle {
    font-size: 36px;
    line-height: 44px;
  }
  smouhaTitle #smouhaTitle {
    font-size: 36px;
    line-height: 44px;
  }
  #buildTxt {
    font-size: 16px;
    line-height: 24px;
  }
  besallaTxt #besallaTxt {
    font-size: 16px;
    line-height: 24px;
  }
  smouhaTxt #smouhaTxt {
    font-size: 16px;
    line-height: 24px;
  }
  #ourTeam {
    font-size: 14px;
    line-height: 20px;
  }
  #dynamic {
    font-size: 32px;
    line-height: 38px;
  }

  .numbers {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 1em 2em;
  }
  .custom-border:before {
    content: none;
  }
  .custom-border {
    padding: 1.5em 0;
    border: 1px solid #eaecf0;
    border-style: solid none solid none;
  }
  .stats {
    margin: 1em 0;
  }
  .custom-border:after {
    content: none;
  }
  #question {
    font-size: 24px;
  }
  #begin {
    font-size: 16px;
  }
  #turnIdeas {
    font-size: 36px;
    line-height: 44px;
  }
  #reqQuestion {
    background: #0f338c;
  }
  #feedImg {
    width: 50px;
    height: 50px;
  }
  #feedDescribe {
    font-size: 13px;
    min-height: unset !important;
  }
  #hire {
    margin-right: 1em;
  }
  .homerAr #hire {
    margin-left: 1em;
    margin-right: auto !important;
  }
  .learnMore {
    margin: 0;
  }
  .btnsDiv {
    display: flex;
    justify-content: space-between;
  }
  .socialBtns div {
    text-align: center;
  }
  .socialBtns a {
    white-space: nowrap;
    display: inline-block;
  }
  .btnsDiv button {
    padding: 0.7em 1em !important;
    font-size: 13px;
  }
  .marginTopFirst {
    margin-top: 64px;
  }
  .btnsDiv {
    max-width: 330px;
  }
  .socialBtns {
    margin-bottom: 0;
    display: block;
  }
  .socialBtns {
    display: flex;
    justify-content: space-between;
  }
  #reqQuestion {
    top: 6.5em !important;
  }
  #clients,
  .eightthDiv {
    margin: 32px 0 !important;
  }
  .socialBtns div {
    margin-bottom: 10px;
    display: inline-block;
  }
  .homerAr .socialBtns div {
    margin-left: 25px;
    margin-right: 0 !important;
  }

  #powerful {
    font-size: 16px;
  }
  .footer {
    background: #101828;
    overflow: hidden;
  }
  .logoW {
    min-width: 8em;
    min-height: 6em;
  }
  .siteMap p {
  }
  .footerTxt {
    margin-top: 1em;
    margin-bottom: 3em;
  }
  .cr {
    flex-direction: column-reverse;
    align-items: start;
    background: #1c2939;
    padding-top: 0;
  }
  .socialLinks {
    width: 100%;
    border-top: 1px solid #475467;
    padding-top: 2em;
  }
  .cr p {
    font-size: 16px;
    font-weight: 400;
    margin: 1.5em 0;
  }
  #footerTwitt {
    margin-left: 0;
  }
  .footer {
    background: #1c2939;
  }

  .ninethDiv div {
    text-align: left !important;
  }
  .trianglePlacement {
    padding: 0;
  }
  .ninethDiv button {
    margin-top: 1em;
  }
  .trianglePlacement {
    position: static;
  }
  #triangles {
    display: none;
  }
  #questionBtn {
    width: 100%;
  }
  #build {
    font-size: 32px;
    line-height: 38px;
  }
  #everything {
    font-size: 16px;
    line-height: 24px;
  }
  .fifthDiv {
    position: relative;
    overflow: hidden;
  }
  #planeMobile {
    display: block;
    height: 84vw;
    width: 84vw;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -65vw;
    right: -52vw;
  }
  #toTop {
    right: 1em;
  }
  .homeAr #turnIdeas {
    line-height: 57px !important;
  }
  .homeAr #turnIdeas span {
    line-height: 56px;
  }
  .firstDiv {
    padding: 0 6vw !important;
  }
  .firstDiv .alignVert {
    display: block;
  }
  .socialBtns a {
    margin-right: 15px;
  }
  .homeAr .socialBtns a {
    margin-right: 0 !important;
    margin-left: 15px;
  }
  .fb {
    margin: 0 !important;
  }

  .TechDiv .techHeader h1 {
    font-size: 40px;
  }
  .TechDiv .techHeader {
    padding: 5em 2em 0em 2em;
  }
  .TechDiv .TechRow {
    padding: 0em 2em 2em 2em;
  }
  .TechDiv .TechRow .techCol1 {
    text-align: center;
    margin-bottom: 4em;
  }
  .techCol1 .techOne .techOneImg,
  .techCol1 .techOne .techTwoImg,
  .techCol1 .techOne .techThreeImg,
  .techCol1 .techOne .techFourImg,
  .techCol1 .techOne .techFiveImg,
  .techCol1 .techOne .techSixImg,
  .techCol1 .techOne .techSevenImg,
  .techCol1 .techOne .techEightImg,
  .techCol1 .techOne .techNineImg,
  .techCol1 .techOne .techTenImg,
  .techCol1 .techOne .techElevenImg,
  .techCol1 .techOne .techTwelveImg {
    background-position: center !important;
  }
  .techCol1 .techOne .techOnetext p {
    max-width: unset;
  }

  .besallaImg .besallaImgOne {
    background-position: center;
    margin-top: -0em;
  }
  .smouhaImg .smouhaImgOne {
    background-position: center;
    margin-top: -0em;
  }
  .besallaImg .besallaImgTwo {
    background-position: center;
    margin-top: -11em;
  }
  .besallaWhiteDiv {
    width: 40%;
  }
  .besallaWhiteDiv .besallaImgLogo {
    height: 3em;
  }
  .smouhaWhiteDiv {
    width: 50%;
  }
  ˝ .smouhaWhiteDiv .smouhaImgLogo {
    height: 3em;
  }
  .smouhaImg {
    margin-bottom: 8em;
  }
}
@media screen and (max-width: 510px) {
  .buildImg .buildImgTwo {
    margin-top: -5em;
  }
  .besallaImg .besallaImgTwo {
    margin-top: -10em;
    height: 5em;
  }
  #besallaTxt {
    font-size: 5vw;
  }
  #smouhaTxt {
    font-size: 5vw;
  }
  .smouhaWhiteDiv {
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .buildImg {
    padding-bottom: 25em;
  }
  .modal-content {
    width: 95%;
  }
  .smouhaWhiteDiv {
    width: 60%;
  }
  .smouhaWhiteDiv .smouhaWhiteDivTitle p {
    font-size: 15px;
  }
}
@media screen and (max-width: 430px) {
  .teamRow .cardCol {
    display: block;
    margin-bottom: 15em !important;
  }
  .teamRow .col-sm-6 .cardImg {
    height: 24em;
    width: 60%;
    display: inline-block;
    position: absolute;
    top: -11em;
    z-index: 0;
    background-size: contain;
  }
  #card .cardLogo {
    display: none;
  }
  #card .cardLogoMob {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    display: inline-block;
    width: 40%;
    position: absolute;
    top: 0em;
    right: 1em;
    height: 100px;
  }
  #card .arabicStyleCardLogoMob {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    display: inline-block;
    width: 40%;
    position: absolute;
    top: 0em;
    left: 1em;
    right: unset;
    height: 100px;
  }
  .teamRow .col-sm-6 .cardInfo {
    font-size: 0.9em !important;
    padding: 5px !important;
  }
  .teamRow .col-sm-6 .cardInfo p {
    margin-top: 7em !important;
    padding: 15px;
    min-height: 0em !important;
  }
  .teamRow .specialCardCol #card .specialCardTitle {
    text-align: left;
  }
  .teamRow .specialCardCol #card .arabicStyleSpecialCardTitle {
    text-align: right;
  }
  .teamRow .col-sm-6 .cardInfo .cardInfoParaArSp {
    margin-top: 12em !important;
  }
  .teamRow .specialCardCol {
    margin-bottom: 10em !important;
  }

  .thirdDiv .titleRow {
    padding-bottom: 20em !important;
  }
}
@media screen and (max-width: 400px) {
  .buildImg .buildImgTwo {
    margin-top: -8em;
  }
  .besallaImg .besallaImgTwo {
    margin-top: -12em;
    height: 3.5em;
  }
  .smouhaImg .smouhaImgOne {
    width: 100%;
  }
  .smouhaWhiteDiv {
    width: 70%;
    padding: 10px 15px 90px 10px;
  }
}
@media screen and (max-width: 380px) {
  .teamRow .col-sm-6 {
    margin-bottom: 18em !important;
  }
  .TechDiv .techHeader {
    padding: 0em 1em 0em 1em !important;
    /* margin-top: -10em; */
  }
  #smouhaTitle span {
    font-size: 35px;
  }
}
