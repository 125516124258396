/* #process p{
    font-family: Druk;
    letter-spacing: 0;
    text-align: left;
    margin: auto;
} */

#process img{
    width: 100%;
}

.processNum{
    font-size: 20em;
    color: #000000;
}

.processTitle{
    font-size: 12em;
    color: #ffffff;

}

.processDesc{
    font-size: 18px;
    color: #ffffff;
    font-family: 'Montserrat' !important;
    font-weight: 400;
}

.process4 {
    min-height: 600px;
    background-image: url("../images/spaceShip.png");
    background-position: top center;
    background-size: cover;
    height: 750px;
}
.processFlib-background2 {
    background-image: url("../images/spaceShipFlip.png");
}
@keyframes zoomInOut {
    0% {
    transform: scale(1);
    }
    50% {
    transform: scale(1.1);
    }
    100% {
    transform: scale(1);
    }
}


#powerful2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bolder;
    font-size: 1.6em;
    color: #ffffff;
    max-width: 500px;
}

.alignVert2{
    display: flex;
    align-items: center;
    margin: 15em 0em 0em 0em;
    padding: 0em 11em;
}

.alignVertArabicStyle {
    padding: 0 5em 0 0em !important;
}

.talk{
    background: #3B6DEA;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    border: none;
    color: #FFFFFF;
    transition: 0.2s;
    padding: 15px 0px 15px 1.5em;
    text-decoration: none;
}
.talk:hover {
    background-color: #62C4F9;
    color: #FFFFFF;
}
.talk .btnlogo {
    width: 18px;
    height: 18px;
    margin: 0px 10px 10px 20px;
}


.fourthDiv { 
    padding: 96px 5vw 0px 5vw;
    display: block;
    position: relative;
}
.analysisRow .analNum {
    width: 100%;
    margin-left: 2em;
}
.designRow .designNum {
    width: 100%;
    margin-left: 4em;
}
.developRow .developNum {
    width: 100%;
    margin-left: 4em;
}
.analysisRow .analNum h1, .designRow .designNum h1, .developRow .developNum h1  {
    font-size: 24em;
    font-family: 'DRUKText';
    color: #000000;
}
.analysisRow .analText .processTitle, .designRow .designText .processTitle, .developRow .developText .processTitle {
    font-size: 8em;
    color: #ffffff;
    font-family: Druk;
    letter-spacing: 1px;
    text-align: left;
    margin: 0.3em auto 0px auto;
}
.analysisRow .analText .processDesc, .designRow .designText .processDesc, .developRow .developText .processDesc  {
    font-size: 20px;
    text-align: left;
    color: #ffffff;
    font-family: 'Montserrat' !important;
    font-weight: 400;
}
.analysisRow .analImg {
    width: 100%;
    margin-top: 2em;
}
.designRow .designImg {
    width: 125%;
    margin: 9em 2em 2em -5em;
}
.developRow .developImg {
    height: 45em;
    width: 195%;
    background-image: url("../images/process3.png");
    background-position: center;
    background-size: cover;
    margin: 0em 0em 0em -20em;
    background-repeat: no-repeat;
}

.processFlib-background3 {
    height: 45em;
    width: 195%;
    background-image: url("../images/process3Flip.png") !important;
    background-position: center;
    background-size: cover;
    margin: 0em -20em 0em 0em !important;
    background-repeat: no-repeat;
}
.fourthDiv {
    overflow: hidden;
}

.analysisRow, .designRow, .developRow{
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 1; 
}

.analysisRow.hide, .designRow.hide, .developRow.hide {
    transform: translateY(30%);
    opacity: 0;
}


.processImgLogo .btnlogo {
    float: right;
    background-position: center;
    background-size: cover;
    background-image: url("../images/bdaiatLogoWhite.png");
    width: 45px;
    height: 45px;
    margin: 4em 4em 0em 2em;
}
.processImgLogo .arabicStyleBdaiatLogoWH {
    float: left;
    background-position: center;
    background-size: cover;
    background-image: url("../images/bdaiatWhiteLogoFlip.png");
    width: 45px;
    height: 45px;
    margin: 4em 4em 0em 2em;
}

.arabicStyleAnalNum {
    margin-right: 3em;
    margin-left: 0;
}
.arabicStyleDesignNum{
    margin-right: 5em;
    margin-left: 0;
}
.arabicStyleDevelopNum {
    margin-right: 1em;
}
.analysisRow .analNum .arabicStyleAnalNumH1, .designRow .designNum .arabicStyleDesignNumH1, .developRow .developNum .arabicStyleDevelopNumH1 {
    font-family: cairo;
}
.arabicStyleDesignImg {     margin: 9em -2em 2em 0em !important; }
.arabicStyleTalk {
    padding: 15px 1.5em 15px 0em;
}
.analysisRow .analText .arabicStyleAnalTextTitle, .developRow .developText .arabicStyleDevelopTextTitle{
    text-align: right;
    margin-bottom: 20px;
}
.designRow .designText .arabicStyleDesignTextTitle{
    text-align: right;
    margin-bottom: 20px;
    font-size: 5em;
}
.analysisRow .analText .arabicStyleAnalTextDesc, .designRow .designText .arabicStyleDesignTextDesc, .developRow .developText .arabicStyleDevelopTextDesc  {
    text-align: right;
    font-family: 'Cairo' !important;
}

@media screen and (max-width: 1400px){
    .designRow .designImg {
        width: 120%;
        margin: 9em 2em 2em -2em;
    }
    .developRow .developImg {    margin: 0em 0em 0em -16em;    }
    .alignVert2 {
        margin: 10em 0em 0em 0em;
        padding: 0em 0em 0em 10em;
    }
    /* .analysisRow, .designRow, .developRow{
        transition: unset;
        opacity: unset; 
    }
    
    .analysisRow.hide, .designRow.hide, .developRow.hide {
        transform: unset;
        opacity: unset;
    } */
    /* .col-lg-5 { width: 45%; } */
    .col5Responsive { width: 45%; }

    .alignVert2 { 
        width: 55%;
        padding: 0 0em 0 5em;
        margin: 20em 0em 0em 0em;
    }
    #turnIdeas { font-size: 45px; }
    #powerful2 { font-size: 1.7em; }
    .talk {
        border-radius: 10px;
        font-size: 18px;
    }
    .arabicStyleDesignNum { margin-right: 0em; }
    .arabicStyleDevelopNum { margin-right: -3em; }
    .developRow .developImg { width: 250%; }
}
@media screen and (max-width: 998px){
    .alignVert2 { 
        width: 55%;
        padding: 0 0em 0 5em;
        margin: 20em 0em 0em 0em;
    }
    /* .analysisRow, .designRow, .developRow{
        transition: unset;
        opacity: unset; 
    }
    .analysisRow.hide, .designRow.hide, .developRow.hide {
        transform: unset;
        opacity: unset;
    } */

    /* .col-lg-2 { width: 10%; }
    .col-lg-4 { width: 35%; }
    .col-lg-5 { width: 45%; }
    .col-lg-6 { width: 55%; } */

    .col2Responsive { width: 10%; }
    .col6Responsive { width: 55%; }
    .col4Responsive { width: 35%; }

    .col5Responsive { width: 45%; }

    .analText, .designText, .developText {
        width: 100%;
        padding: 1em;
    }
    .analysisRow .analText .processTitle, .designRow .designText .processTitle, .developRow .developText .processTitle
    {     font-size: 7em;    }
    .analysisRow .analText .processDesc, .designRow .designText .processDesc, .developRow .developText .processDesc 
    {    font-size: 18px;    }
    .analysisRow .analNum, .designRow .designNum, .developRow .developNum {     margin-left: 0em;    }
    .analysisRow .analNum h1, .designRow .designNum h1, .developRow .developNum h1  {     font-size: 20em;    }
    .analysisRow .analImg {     margin-top: 5em;    }
    .developRow .developImg { 
        height: 35em;
        width: 42em;
    }
    #turnIdeas { font-size: 40px; }
    #powerful2 { font-size: 1.6em; }
    .talk {
        border-radius: 10px;
        font-size: 18px;
    }
}

@media screen and (max-width: 895px){

}

@media screen and (max-width: 790px){
    .analysisRow .analNum h1, .designRow .designNum h1, .developRow .developNum h1  {     font-size: 15em;    }
    .analysisRow .analText .processTitle, .designRow .designText .processTitle, .developRow .developText .processTitle
    {     font-size: 5em;    }
    .analysisRow .analText .processDesc, .designRow .designText .processDesc, .developRow .developText .processDesc 
    {    font-size: 18px;    }
    .developRow .developImg {
        height: 25em;
        width: 35em;
    }
}

@media screen and (max-width: 665px){
    .alignVert2 { 
        margin: 12em 0em 0em 0em;
    }
}

@media screen and (max-width: 595px){
    .analysisRow .analNum h1, .designRow .designNum h1, .developRow .developNum h1  {     font-size: 12em;    }
    .analysisRow .analText .processTitle, .designRow .designText .processTitle, .developRow .developText .processTitle
    {     font-size: 3em;    }
    .analysisRow .analText .processDesc, .designRow .designText .processDesc, .developRow .developText .processDesc 
    {    font-size: 15px;    }
    .arabicStyleDevelopNum {     margin-right: -1em;    }
    .developRow .developImg {
        height: 23em;
        width: 44em;    
    }
}

@media screen and (max-width: 576px){
    .analysisRow .analNum h1, .designRow .designNum h1, .developRow .developNum h1  {     font-size: 12em;    }
    .analysisRow .analText .processTitle, .designRow .designText .processTitle, .developRow .developText .processTitle
    {
        font-size: 2em;
        width: 200%;    
        margin-bottom: 20px;
    }
    .analysisRow .analText .processDesc, .designRow .designText .processDesc, .developRow .developText .processDesc 
    {    font-size: 15px;    }
    .fourthDiv {     padding: 96px 5vw 0px 5vw !important;    }
}

@media screen and (max-width: 575px){
    .darkMode .marginTopFirst{ margin-top: 260px; }
}

@media screen and (max-width: 490px){
    .developRow .developImg { 
        height: 24em;
        width: 30em;
    }
    .developRow .developImg {
        height: 22em;
        width: 43em;
    }
}

@media screen and (max-width: 400px) {
    .darkMode .drkImg {
        height: unset !important;
    }
    .analysisRow .analNum h1, .designRow .designNum h1, .developRow .developNum h1  {     font-size: 10em;    }
    .analysisRow .analText .processTitle, .designRow .designText .processTitle, .developRow .developText .processTitle
    {
        font-size: 3em;
        width: 200%;    
    }
    .analysisRow .analText .processDesc, .designRow .designText .processDesc, .developRow .developText .processDesc 
    {    font-size: 13px;    }
    #turnIdeas {     font-size: 32px;    }
    #powerful2 {     font-size: 1em;    }
    .btnsDiv button {     font-size: 14px;    }
    .developRow .developImg {
        height: 32em;
        width: 35em
    }
    .developRow .processFlib-background3 {
        height: 27em;
        width: 38em;
    }
    .process4 {height: unset;}
    .alignVert2 {
        width: 80%;
        padding: 0 0em 0 2em;    
    }
    .col5Responsive { width: 20%; }
    .processImgLogo .btnlogo {     
        margin: 3em 1em 0em 1em; 
        width: 40px;
        height: 40px;
    }
    .talk .btnlogo {     margin: 0px 10px 10px 10px;    }
    .arabicStyleAnalNum {     margin-right: 0em;    }
    .analysisRow .analText .arabicStyleAnalTextTitle, .developRow .developText .arabicStyleDevelopTextTitle,
    .designRow .designText .arabicStyleDesignTextTitle
    { font-size: 1.7em; }
    .analysisRow{
        transition: unset;
        opacity: unset; 
    }
    .analysisRow.hide{
        transform: unset;
        opacity: unset;
    }
}