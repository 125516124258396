.arabicStyleCompTitle1 {
    padding-left: 10px;
    padding-right: 0 !important;
    font-family: cairo !important;
}
.arabicStyleCompTitle2 {
    font-family: cairo !important;
}
.arabicStyleCompCard1P {
    font-family: Druk !important;
    color: #babdc3;
}
.arabicStyleCompTitle1p, .arabicStyleCompTitle2p { text-align: right !important; }
.item .arabicStyleCompCardSmImage2 {
    float: left !important;
    margin: 35px 5px 0px 22px !important;
}
.item .arabicStyleCompCardSmImage5 {
    float: left !important;
    margin: 35px 5px 0px 22px !important;
}
.arabicStyleCompCard2p, .arabicStyleCompCard4p { margin-top: -90px !important; }
.arabicStyleCompCard3p { margin-top: -110px !important; }
.grid-container .arabicStyleCompCard7 { padding: 20px 10px 0px 20px !important; }

.darkMode .aboutUs-container {
    padding-top: 0;
}

@keyframes zoomInOut {
    0% {
    transform: scale(1);
    }
    50% {
    transform: scale(1.1);
    }
    100% {
    transform: scale(1);
    }
}

.drkImg {
    position: relative;
    overflow: hidden;
    display: block;
}

.drkImg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-image: url("./images/galaxy2.jpg") ; */
    background-size: cover;
    background-position: center;
    animation: zoomInOut 30s infinite;

    transform: translate3d(0, 0, 0); /* Initial transform for the background only */
    transition: transform 0.9s ease; /* Add a transition for smooth animation */
    z-index: -1; /* Set a negative z-index to place it behind other elements */
}

/* English background */
.enBackground::before {
    background-image: url("../../src/images/rocket.png");
}

/* Arabic background */
.arBackground::before {
    background-image: url("../../src/images/rocketFlip.jpg");
}

.mission{
    font-family: 'Druk';
    font-size: 16em;
    margin-top: -135px;
    color: #F9FAFB;
}
.arabicStyleAbout{
    font-family: cairo;
    font-size: 12em;
    margin-top: -110px;
    color: #F9FAFB;
}
.vision{
    font-family: 'Druk';
    font-size: 16em;
    margin-top: -180px;
    color: #F9FAFB;
}
.arabicStyleBdaiat{
    font-family: cairo;
    font-size: 12em;
    margin-top: -85px;
    color: #F9FAFB;
}

.missionDiv {
    position: relative;
    display: block;
    background-image: url("../images/aboutbck.png") ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}

.missionCol .mission2{  padding: 3em 0em 0em 6em; }
.visionCol .vision2 { padding: 3em 6em 0px 1em; }

.missionCol .arabicStyleMissionTwo { padding: 3em 6em 0em 0em; }
.visionCol .arabicStyleVisionTwo { padding: 3em 1em 0px 6em; }

.missionCol .mission2 .missionTitle h1 {
    font-family: 'Druk';
    color: #31539a;
    font-size: 9em;
}

.missionCol .mission2 .missionInfo p, .visionCol .vision2 .visionInfo p { 
    color: #a8a8aa;
    font-size: 1.4em;
    font-weight: 200;
}

.missionCol .mission2 .missionInfo span { 
    font-weight: 600;
}

.visionCol .vision2 .visionTitle h1 {
    font-family: 'Druk';
    color: #a8a8aa;
    font-size: 9em;
}

.companyDiv {
    position: relative;
    display: block;
    background-color: #000000;
    /* height: 20em; */
}
.companyDiv .companyRow1 .compTitleCol {
    padding: 2em 0em 1em 6em;
    margin-top: 0;
}
.companyDiv .companyRow1 .arabicStyleCompTitleCol {
    padding: 2em 6em 1em 0em;
}
.companyDiv .companyTitle .compTitle1, .companyDiv .companyTitle .compTitle2  { display: inline-block !important; }
.companyDiv .companyTitle .compTitle1 h1 {
    color: #5c5c5c;
    font-family: 'Druk';
    font-size: 7em;
    padding-right: 10px;
}
.companyDiv .companyTitle .compTitle2 h1 {
    color: #a8a8aa;
    font-family: 'Druk';
    font-size: 7em;
}
.companyDiv .companyRow1 .compImgCol { margin-top: -40px; }
.compImgCol .compImage {
    width: 100%;
    height: 22em;
}
.compImgCol .compImage #computerImg {
    width: 20em;
    height: 20em;
}

.companyGrid { padding: 0px 10px 200px 10px; }
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
    gap: 15px; /* Adjust the gap between items as needed */
}
.grid-item {
    display: flex; /* Items in a row */
    font-family: Druk !important;
    transition: transform 0.5s ease-in-out;
}
.grid-item:hover {
    transform: scale(1.05);
}
.item {
    margin: 0; /* Reset default margin */
}
.item-large {
    width: 60%;
    background-color: #F9FAFB;
    margin-right: 5px;
    /* height: 400px; */
}
.item-small-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr); /* Two rows for small items */
    gap: 5px; /* Adjust the gap between small items */
    width: 40%; /* Total width of small items */
}
.item-small {
    width: 100%; /* Full width of the container */
    height: 200px;
}
.grid-container .compCard1{
    padding: 20px 20px 0px 20px;
    height: 17em;
}
.grid-container .compCard2, .grid-container .compCard3, .grid-container .compCard4, .grid-container .compCard5, .grid-container .compCard6  {
    padding: 20px 20px 0px 20px;
}
.grid-container .compCard7 {
    padding: 20px 20px 0px 10px;
    width: 80%;
}
.item3 .compCardInfo3 { width: 80%; }
.grid-container .compCard6 .astroImage {
    height: 383px;
    width: 100%;
}
.lastItem .compCard6 { background-color: #000000; }
.lastItem .compCard6 #logoAstro {
    width: 100%;
    height: auto;
    margin-top: 45px;
}
.grid-container .compCard1 #cardTitle {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
}
.grid-container .compCard1 p {
    text-align: right;
    font-family: 'Druk';
    font-size: 17em;
    margin: 0;
    color: #31539a;
    padding-right: 35px;
    margin-top: -95px;
    height: 300px;
}
.grid-container .compCard1 #cardYear {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}
.arabicStyleItemL { 
    margin-right: 5px; 
    margin-left: 0;
}
.item .compCardInfo p {
    padding: 20px 20px 0px 20px;
    font-weight: 600;
    font-family: 'DrukText';
}
.companyItemOne {
    text-align: right;
    padding: 8px;
    background-color: #31539a;
}
.companyItemThree {
    background-color: #F9FAFB;
    text-align: right;
    padding: 8px;
}
.companyItemFive {
    text-align: right;
    padding: 8px;
    background-color: #3d3d3b;
}
.companyItemSeven {
    text-align: right;
    padding: 8px;
    background-color: #F9FAFB;
}
.companyItemNine {
    text-align: right;
    padding: 8px;
    background-color: #5c5c5c;
}
.item .compCardSmImage { margin: 10px 5px 0px 10px; }
.item .compCardSmImage img { 
    height: 40px;
    width: 40px;
}
.item #compTit1 { 
    text-align: left;
    font-family: 'Druk';
    font-size: 3.9em;
    margin-bottom: 0;
    color: #ab983e;
    margin-top: 10px;
}
.item2 #compTit1 { 
    color: #31539b;
    margin-top: 0px;
}
.item #compTit2 { 
    text-align: left;
    font-family: 'Druk';
    font-size: 4em;
    margin-top: -45px;
    color: #ab983e;
}
.companyItemTwo { 
    padding: 15px 15px 0px 15px;
    background-color: #c1c1c1;
}
.companyItemTwo #compTit3 {
    font-family: 'Druk';
    font-size: 6em;
    color: #e5e5e5;
    margin-bottom: 0;
    margin-top: -15px;
}
.companyItemTwo #compTit4 {
    font-family: 'Druk';
    color: #e5e5e5;
    font-size: 4em;
    margin-top: -45px;
}

.item .compCardSmImage2 { margin: 35px 5px 0px 25px; }
.item .compCardSmImage2 img { 
    height: 35px;
    width: 35px;
}
.item .compCardSmImage3 { margin: 20px 15px 0px 20px; }
.item .compCardSmImage3 img { 
    height: 90px;
    width: 100%;
}
.item .compCardSmImag4 { text-align: left; }
.item .compCardSmImag4 img { 
    height: 80px;
    width: 80px;
}
.item .compCardSmImage5 { margin: 35px 5px 0px 15px; }
.item .compCardSmImage5 img { 
    height: 35px;
    width: 35px;
}
.item .compCardSmImag6 { margin: 10px 15px 0px 0px; text-align: right; }
.item .compCardSmImag6 img { 
    height: 90px;
    width: 100px;
}
.item .compCardSmImage7 {
    margin: 15px 15px 0px 0px;
    text-align: left;
}
.item .compCardSmImage7 img { 
    height: 55px;
    width: 50%;
}
.grid-container .compCard2 p {
    text-align: right;
    font-family: 'Druk';
    font-size: 17em;
    margin: 0;
    color: #ab983e;
    padding-right: 35px;
    margin-top: -160px;
    height: 300px;
}
.grid-container .compCard2 #cardTitle {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
}
.grid-container .compCard2 #cardYear {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}
.item .compCardInfo2 p {
    padding: 10px 20px 0px 20px;
    font-weight: 600;
    font-family: 'DrukText';
}
.companyItemFour {
    background-color: #000000;
    padding: 8px;
}
.companyItemFour #compTit3 {
    font-family: 'Druk';
    font-size: 6em;
    color: #F9FAFB;
    margin-bottom: 0;
    margin-top: -15px;
}
.companyItemFour #compTit4 {
    font-family: 'Druk';
    color: #F9FAFB;
    font-size: 4em;
    margin-top: -45px;
}
.grid-container .compCard3 p {
    text-align: right;
    font-family: 'Druk';
    font-size: 17em;
    margin: 0;
    color: #000000;
    padding-right: 35px;
    margin-top: -180px;
    height: 300px;
}
.grid-container .compCard7 #compTit15 {
    text-align: right;
    font-family: 'Druk';
    font-size: 17em;
    margin: 0;
    color: #ffffff;
    padding-right: 35px;
    margin-top: -85px;
    height: 300px;
}
.grid-container .compCard3 #cardTitle {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
}
.grid-container .compCard3 #cardYear {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}
.grid-container .compCard4 p {
    text-align: right;
    font-family: 'Druk';
    font-size: 17em;
    margin: 0;
    color: #000000;
    padding-right: 35px;
    height: 300px;
    margin-top: -155px;
}
.grid-container .compCard4 #cardTitle {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
}
.grid-container .compCard4 #cardYear {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}
.grid-container .compCard5 p {
    text-align: right;
    font-family: 'Druk';
    font-size: 17em;
    margin: 0;
    color: #000000;
    padding-right: 10px;
    margin-top: -160px;
    height: 300px;
}
.grid-container .compCard5 #cardTitle {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
}
.grid-container .compCard5 #cardYear {
    font-family: cairo;
    font-size: 1em;
    font-weight: 400;
    text-decoration: underline;
    color: #a3a3a3;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}
.companyItemSix {
    background-color: #F9FAFB;
    padding: 8px;
}
.companyItemSix #compTit5 {
    font-family: 'Druk';
    font-size: 6em;
    color: #000000;
    margin-bottom: 0;
    margin-top: -15px;
}
.companyItemSix #compTit6 {
    font-family: 'Druk';
    color: #000000;
    font-size: 4em;
    margin-top: -45px;
}
.item #compTit7 { 
    text-align: left;
    font-family: 'Druk';
    font-size: 6.5em;
    margin-bottom: 0;
    color: #F9FAFB;
    margin-top: -25px;
}
.companyItemSeven {
    background-color: #F9FAFB;
    padding: 8px;
}
.item #compTit10 { 
    text-align: left;
    font-family: 'Druk';
    font-size: 6em;
    margin-bottom: 0;
    color: #5b5b5b;
    margin-top: -100px;
}

.item #compTit11 { 
    text-align: left;
    font-family: 'Druk';
    font-size: 4.1em;
    margin-bottom: 0;
    color: #5b5b5b;
    margin-top: -44px;
}
.companyItemEight {
    background-color: #000000;
    padding: 8px;
}
.companyItemEight #compTit8 {
    font-family: 'Druk';
    font-size: 6em;
    color: #c1c1c1;
    margin-bottom: 0;
    margin-top: -15px;
}
.companyItemEight #compTit9 {
    font-family: 'Druk';
    color: #c1c1c1;
    font-size: 4em;
    margin-top: -45px;
}
.companyItemTen {
    background-color: #000000;
    padding: 8px;
}
.companyItemTen #compTit12 {
    font-family: 'Druk';
    font-size: 6em;
    color: #c1c1c1;
    margin-bottom: 0;
    margin-top: -15px;
}
.companyItemTen #compTit13 {
    font-family: 'Druk';
    color: #c1c1c1;
    font-size: 4em;
    margin-top: -45px;
}
.item2 #compTit14 { 
    color: #ffffff;
    margin-top: -15px;
    text-align: left;
    font-family: 'Druk';
    font-size: 7em;
    margin-bottom: 0;
}
.item3 .compCardInfo3 p { 
    padding: 10px 20px 0px 20px;
    font-weight: 600;
    font-family: 'DrukText';
    color: #ffffff;
    font-size: 25px;
}
.lastCard { border: 1px solid #414141; }
.noGrid { grid-template-rows: unset; }
.item3 .compCardInfo3 { width: 80%; }
.item3 .compCardInfo3 p {
    padding: 10px 10px 0px 10px; 
    font-weight: 600;
    font-family: 'DrukText';
    color: #ffffff;
    font-size: 25px;
}
.aboutImgLogo {
    position: absolute;
    top: 5%;
    width: 100%;
}
.aboutImgLogo .btnlogo {
    float: right;
    background-position: center;
    background-size: cover;
    background-image: url("../images/bdaiatLogoWhite.png");
    top: 30%;
    width: 30px;
    height: 30px;
    margin: 0em 2em 0em 2em;
}
.aboutImgLogo .arabicStyleBdaiatLogoWH {
    float: left;
    background-position: center;
    background-size: cover;
    background-image: url("../images/bdaiatWhiteLogoFlip.png");
    top: 30%;
    width: 30px;
    height: 30px;
    margin: 0em 2em 0em 2em;
}

@media screen and (max-width: 1400px){
    .grid-container { display: unset;}
    .grid-item { margin-bottom: 15px;}
    .grid-container .compCard6 .astroImage{ 
        text-align: center;
        height: 500px;
    }
    .lastItem .compCard6 #logoAstro {
        width: 25em;
        height: 35em;
        margin-top: 0;
    }
    .grid-container .compCard7 compTit15 { text-align: left; }
    .arabicStyleCompCard3p { margin-top: -90px !important; }
    .item .compCardSmImage3 img{ width: 25%; }
    .item .compCardSmImage7 img { width: 18%; }
    .grid-item:hover {
        transform: scale(1.03);
    }
}

@media screen and (max-width: 995px){
    .missionCol .mission2 {           padding: 3em 3em 0em 3em;    }
    .visionCol .vision2 {         padding: 3em 3em 0px 3em;    }

    .missionCol .arabicStyleMissionTwo { padding: 3em 3em 0em 3em; }
    .visionCol .arabicStyleVisionTwo { padding: 3em 3em 0px 3em; }
}
@media screen and (max-width: 951px){
    .companyDiv .companyRow1 .compTitleCol {     
        padding: 3em 0em 3em 2em;
        text-align: left;
    }
    .compImgCol .compImage { height: 20em;}
    .compImgCol .compImage #computerImg { 
        width: 16em;
        height: 17em;
    }
    .companyImg { text-align: center; }
    .item .compCardSmImage3 img{ width: 35%; }
    .item .compCardSmImage7 img { width: 22%; }
}
@media screen and (max-width: 895px){
    .missionCol .mission2 {       padding: 3em 0em 0em 3em;    }
    .visionCol .vision2 {         padding: 3em 0em 0px 3em;    }

    .missionCol .arabicStyleMissionTwo { padding: 3em 3em 0em 3em; }
    .visionCol .arabicStyleVisionTwo { padding: 3em 3em 0px 3em; }
}

    
@media screen and (max-width: 776px){
    #mission {     font-size: 11em;    }
    #vision { 
        font-size: 11em;
        margin-top: -115px;
    }
    .missionCol .mission2 {     padding: 3em 0em 0em 3em;    }
    .visionCol .vision2 {     padding: 3em 0em 0px 3em;    }

    .missionCol .arabicStyleMissionTwo { padding: 3em 3em 0em 3em; }
    .visionCol .arabicStyleVisionTwo { padding: 3em 3em 0px 3em; }

    .item .compCardSmImage3 img{ width: 50%; }
    .item .compCardSmImage7 img { width: 30%; }
}

@media screen and (max-width: 576px){
    .missionCol .mission2 {     padding: 3em 2em 0em 2em;    }
    .visionCol .vision2 {     padding: 3em 2em 0px 2em;    }
    .companyDiv .companyRow1 .compTitleCol {
        padding: 3em 2em 2em 2em;
        text-align: center;
    }
    .compImgCol .compImage { text-align: center;}
    .compImgCol .compImage #computerImg {
        width: 20em;
        height: 20em;
    }
    .lastItem .compCard6 #logoAstro {
        width: 19em;
        height: 33em;
        margin-top: 0;
    }
    #mission {    margin-top: -45px;    }
    #vision {     margin-top: -80px;    }
    .item .compCardSmImage3 img{ width: 75%; }
    .item .compCardSmImage7 img { width: 42%; }
}
@media screen and (max-width: 490px){
    .item-large { width: 50%;}
    .grid-container .compCard1 {     padding: 20px 10px 0px 10px;    }

    .grid-container .compCard1 #cardYear, .grid-container .compCard2 #cardYear, .grid-container .compCard3 #cardYear,
    .grid-container .compCard4 #cardYear, .grid-container .compCard5 #cardYear
    { 
        font-size: 0.9em;
    }

    .item .compCardInfo p {
        padding: 20px 10px 0px 10px;
        font-size: 0.9em;
    }
    .companyItemFive, .companyItemSix { height: auto;}
    .item-small-container { width: 50%; }
    .item #compTit1 {
        margin-top: 15px;
        font-size: 3em;
    }
    .item #compTit2 {  
        margin-top: -30px;
        font-size: 3em;
    }
    .companyItemTwo {     padding: 15px 15px 0px 8px;    }
    .companyItemTwo #compTit3, .companyItemSix #compTit5 {
        font-size: 5em;
        margin-top: 0px;
    }
    .companyItemTwo #compTit4, .companyItemSix #compTit6 {
        font-size: 3.5em;
        margin-top: -40px;
    }
    .grid-container .compCard2, .grid-container .compCard4, .grid-container .compCard5, .grid-container .compCard6
    {     
        padding: 5px 10px 0px 10px;
    }

    .grid-container .compCard1 #cardTitle, .grid-container .compCard2 #cardTitle, .grid-container .compCard3 #cardTitle,
    .grid-container .compCard4 #cardTitle, .grid-container .compCard5 #cardTitle
    { 
        font-size: 0.8em;
    }

    .item .compCardSmImage2 {     margin: 0px 5px 0px 25px;    }
    .item .compCardSmImage2 img, .item .compCardSmImage5 img {
        height: 25px;
        width: 30px;
    }
    .grid-container .compCard1 p, .grid-container .compCard2 p, .grid-container .compCard3 p, .grid-container .compCard4 p,
    .grid-container .compCard5 p
    {
        font-size: 14em;
        margin-top: -70px;
        height: 270px;
    }
    .item .compCardInfo2 p {
        padding: 10px 10px 0px 10px;
        font-size: 0.8em;
    }
    .companyItemFour #compTit3, .companyItemEight #compTit8, .companyItemTen #compTit12 {
        font-size: 5em;
        margin-top: 0px;
    }
    .companyItemFour #compTit4, .companyItemEight #compTit9, .companyItemTen #compTit13 {
        font-size: 3.5em;
        margin-top: -40px;
    }
    .grid-container .compCard3 {     padding: 5px 10px 0px 10px;    }
    .item .compCardSmImag4 img {
        height: 70px;
        width: 80px;
    }
    .item #compTit7 {
        font-size: 5em;
        margin-top: 0px;
    }
    .item .compCardSmImage5 {     margin: 0px 5px 0px 15px;    }
    .item .compCardSmImag6 {
        margin: 10px 10px 0px 0px;
    }
    .item .compCardSmImag6 img {
        height: 90px;
        width: 85px;
    }
    .item #compTit10 {
        font-size: 4em;
        margin-top: -75px;
    }
    .item #compTit11 {
        font-size: 3em;
        margin-top: -15px;
    }
    .item .compCardSmImage7 {     margin: 15px 15px 0px 0px;    }
    .item .compCardSmImage7 img {
        height: 55px;
        width: 50%;
    }
    .grid-container .compCard6 .astroImage {height: 380px;}
    .lastItem .compCard6 #logoAstro {
        width: 10em;
        height: 27em;
        margin-top: 0;
    }
    .grid-container .compCard7 {
        padding: 5px 10px 0px 10px;
        width: 80%;
    }
    .grid-container .compCard7 #compTit15 {
        font-size: 12em;
        margin-top: -55px;
        height: 270px;
    }
    .item3 .compCardInfo3 { width: 80%; }
    .item3 .compCardInfo3 p {
        padding: 10px 10px 0px 10px; 
        font-weight: 600;
        font-family: 'DrukText';
        color: #ffffff;
        font-size: 25px;
    }
    .item .arabicStyleCompCardSmImage2 {    margin: 35px 5px 0px 8px !important;    }
    .arabicStyleCompCard3p {     margin-top: -50px !important;    }
    .item .arabicStyleCompCardSmImage5 {     margin: 35px 5px 0px 0px !important;    }
    .lastItem .compCard6 #logoAstro {
        width: 14em;
        height: 27em;
    }
    .grid-container .compCard1 p { 
        font-size: 12em;
        margin-top: -40px;
    }
    .item .compCardInfo p {     padding: 50px 10px 0px 10px;    }
    .grid-container .compCard2 p, .grid-container .compCard3 p, .grid-container .compCard4 p, .grid-container .compCard5 p {     font-size: 12em;}
    .arabicStyleCompCard2p, .arabicStyleCompCard4p {       margin-top: -50px !important;    }
    .mission { 
        font-size: 9em;
        margin-top: 95px;
    }
    .vision { 
        font-size: 9em;
        margin-top: 20px;
    }
    .missionCol .mission2 .missionTitle h1, .visionCol .vision2 .visionTitle h1{     
        font-size: 6em;    
        margin-bottom: 25px;
    }

    .arabicStyleAbout {
        font-size: 10em;
        margin-top: -50px;
    }
    .arabicStyleBdaiat {
        font-size: 9em;
        margin-top: -50px;
    }
}
@media screen and (max-width: 400px) {
    .item .arabicStyleCompCardSmImage2 {     margin: 35px 5px 0px -5px !important;    }
    .arabicStyleCompCard2p, .arabicStyleCompCard4p {
        padding-right: 0px !important;
        margin-top: -60px !important;
    }
    .arabicStyleCompCard3p { 
        padding-right: 10px !important;
        margin-top: -70px !important;
    }
    .lastItem .compCard6 #logoAstro {
        width: 11em;
        height: 23em;
    }
    .grid-container .compCard6 .astroImage {     height: 411px;    }
    .arabicStyleAbout {
        font-size: 7em;
        margin-top: 1em;
    }
    .arabicStyleBdaiat {
        font-size: 7em;
        margin-top: -25px;
    }
    .mission {     margin-top: 110px;  }
    .vision {     margin-top: -95px;  }
    .grid-container .compCard1 p, .grid-container .compCard2 p, .grid-container .compCard3 p, .grid-container .compCard4 p,
    .grid-container .compCard5 p
    {     padding-right: 25px;    }
    .item .compCardSmImage2 { margin: 25px 5px 0px 0px; }
    .item .compCardSmImage5 { margin: 25px 5px 0px 0px; }
    .companyDiv .companyTitle .compTitle2 h1 { font-size: 6em; }
}


/* .compTitleCol .companyTitle { padding: 2em 2em 2em 5em; } */
#nice{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
text-transform: uppercase;
color: #3B6DEA;
} 
#nice span{
    border-bottom: 2.5px solid #3B6DEA;
    width: 30px;
  position: relative;
  bottom: 10px;

  border-radius: 2px;
  margin-left: 5px;}
  #aboutUsTop{

padding: 6vw;
align-items: center;
background: #F7F9FE;
  }
  #topCover{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #about{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    letter-spacing: -0.02em;
    /* Gray/900 */
    
    color: #101828;  }

    #aboutCompany{
        font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
max-width: 400px;
font-size: 20px;


color: #475467;
    }
    #aboutUspoint1{
        padding: 96px 6vw;
    }
    .compImg{
        height: 28vw;
        width: 100%;
        margin: 96px 0 !important;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size:cover;
    }
    #flexible{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
    
        letter-spacing: -0.02em;
        max-width: 350px;
        /* Gray/900 */
        
        color: #101828;
        
    }

    .fi{
        width: 80px;
        height: 80px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size:cover;
        margin-bottom: 1em;
    }
    .our{
        border: 1px solid #E4E7EC;
border-radius: 8px;
padding: 56px 48px;
    }
    #history{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
       
        letter-spacing: -0.02em;
        
        /* Gray/900 */
        
        color: #101828;
    }
    #historyData{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
 line-height: 28px;
        /* or 156% */
        
        
        /* Gray/600 */
        
        color: #475467;
        
    }

    .whyUs-about #img2{
height: 16.6vw !important;
    }
    .whyUs-about  #img3,  .whyUs-about #img5{
        width: 13.3vw !important;
        height: 8.9vw !important;   
    }
    .whyUs{
        padding: 96px 6vw;
        background: #F9FAFB;

    }
    .imgsRow2{
        display: flex;
        justify-content: center;
    }
    .whyUs .request{
        padding: 13.5px 16px;
    }
    #img1{
        margin-bottom: 12px;
        margin-right:16px ;
    }
   .homeAr #img1{
        margin-right:auto !important ;
        margin-left:16px ;

    }
    #img2{
      height: 15vw !important;
      margin-bottom: 12px;
    }
    #img3{
        height: 13.4vw;
        width: 13.4vw;
    }
    #img4{
      
        margin:0 16px;
        height: 16.7vw !important;
    }
    #img5{
        height:11vw;
        width: 13.4vw;
    }
    #img1, #img2, #img4{
        height: 11.1vw;
        display: inline-block;
        width: 11.1vw;
    }

    #img1, #img2,#img3, #img4,#img5{
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px){


    #img3{
        height: 15vw;
        width: 30vw;
    }

#img1, #img2, #img4 {
    height: 23vw;
    width: 23vw;
}
#img5 {
    height: 15vw;
    width: 30vw;
}
    #img4 {

     
        height: 33vw !important;}

        #img2 {
            height: 33vw !important;
       }
    }
    @media  screen and (max-width: 1260px){
        #aboutUspoint1{

            padding: 2em 3vw;
        }
        .whyUs{
            padding: 6vw 3vw;

        }
    }
    @media screen and (max-width: 575px){

  

    .compImg{
        height: 45vw;
        background-position: center;
    }
    #flexible{
        font-weight: 600 !important;
        font-size: 30px;
    }
}
