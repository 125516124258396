@import url('https://fonts.googleapis.com/css2?family=Inter&family=Cairo:wght@200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


@font-face {
    font-family: 'Druk';
    src: url('./fonts/DrukCond-Super-Trial.otf') format('truetype')
}

@font-face {
    font-family: 'DrukText';
    src: url('./fonts/DrukText-Medium-Trial.otf') format('truetype')
}

body {
    background-color: #000000; 
}

.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px; /* Adjust height as needed */
    background-color: #007bff; /* Loading bar color */
    z-index: 9999;
    transition: width 0.3s ease-in-out; /* Smooth transition animation */
}

.darkMode{
    background:  radial-gradient(113.57% 46.74% at 50% 50%, #0B2463 0%, #061539 100%);
    // scroll-behavior: smooth;
}

.homeAr{
    
    
    p,  button,  input,select, label,span, input::placeholder, textarea::placeholder, textarea{
    font-family: cairo !important;
  }
}
.companyGrid p, #compTit1 , #compTit2, #compTit3, #compTit4, #compTit5, #compTit6, #compTit7, #compTit8,
#compTit9, #compTit10, #compTit11, #compTit12, #compTit13, #compTit14, #compTit15
{
    font-family: 'Druk' !important;
}

.companyGrid p:last-child{
    font-family: 'DrukText' !important;
}
.darkMode{
    #feedbackHeader .logo{
        width: 229.835px;
height: 104.525px;
    }
    #myBtn{
        color:#09A2F6
    }
.sixthDiv .shadow1{
    top: -30vw;
 
}
.img-dark{
    display: block;
  }
  .img-light{
    display: none;
  }
    .shadow1{
        width: 58vw;
        height: 60vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      position: absolute;
      left: -28vw;
      top: -14em;
      z-index: 0;
      }
.homeAr .shadow1{
    left: auto !important;
    right: -28vw;

}

.eightthDiv .shadow2{
    top: auto ;
    bottom: 10em !important;
    right: -20vw;

}
.homeAr .eightthDiv .shadow2{

    right: auto !important;
    left: -35vw;

}
.projectDiv, .spaceBtn{
    position: relative;
    z-index: 1;
}

.shadow2{
    width: 68vw;
    height: 79vw;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  position: absolute;
  right: -24vw;
  top: -18em;
  z-index: 0;
  }
.homeAr .shadow2{
right: auto !important;
left: -24vw;

}

   .blackBck{
        display: block;
        position: absolute;
        z-index: 0;
        left: 0vw;
        top: -15px;
        width: 80vw;
        height: 68vw;

   }
   .client{
       background-color: #C5D4F9;
   }
   #clients{
       margin: 0;
   }

   .changeBckg{
    background: radial-gradient(circle, rgba(11,36,99,1) 0%, rgba(11,36,99,1) 65%, rgba(6,21,57,1) 83%);
  
   }
   .qoute1{
       position: absolute;
       background: rgba(10, 31, 86, 1);
       border-radius: 100%;
display: flex;
align-items: center;
justify-content: center;
       width: 170px;
       height: 170px;

    background-position: cqoute1;
   }
   .home-container #reqQuestion{
top: -6.5em;
   }
   .eightthDiv{
    background-color: rgb(5, 21, 57);
    // padding-bottom: 288px;
   }
   .home-container .footer{
       padding-top: 25px;
   }
   .projImg{
    background:  #62c4f939;


   }
   .footer{
    background:   radial-gradient(113.57% 46.74% at 50% 50%, #0B2463 0%, #061539 100%)
   }
   .home-container  .ninethDiv{
    background: #061539;}
    .serviceForm p, #type1 p,  #type1 svg,#projName ,#projDetail{
       color: white !important;
   }
   .serviceForm .errMsg{
       color: #F04438 !important;
   }

   .projType{
    background: rgba(244, 243, 255, 0.10)
!important}
   .projectDiv,#viewProj{
    border: 1px solid rgba(228, 231, 236, 0.00);
    background: rgba(255, 255, 255, 0.10);
   }
 
   #viewProj{
       background: transparent;
       color: white;
   }
   .qoute1 div{
       width: 100px !important;
       height: 80px !important;
       
   }
   .qoute1{ 
    left: 3vw;
    right: auto !important;
          top: -80px;
   }
   .homeAr   .qoute1{
       left: auto !important;
       right: 3vw ;
   }
  .homeAr #smile{
       left: 6vw;
       right:  auto !important;
   }
   .quotes2{
       background-image: url("./images/quoteDark.svg") !important;

   }
 
    .toggOpen{
    padding-top: 88px;
    background-color: #061539;

   }
   .mobileLinks p,  .toggOpen select{
       color: white;
   }
   .toggOpen select{
       background-color: transparent;
       margin-bottom: 7px !important;

   }
   .changeBakground .topNavbar{
    background-color: #061539;
  
   }
   .jobDescribe{
    background-color: #061539;

   }
   .teamLi p svg{
       color: #62c4f9 !important;
   }
   .aboutUs-container{
       padding-top: 92px;
   }
   .aboutUs-container #teamMember .square{
   border: 1px solid var(--primary-500, #1851DF);
   }
   #teamMember{
    background: #061539;
   }
   #aboutUsTop{
       background-color: #0B2463;
   }
   #member #memberData{
    background:  #0F338C;

   }


   #memberData p, #careerName,.jobDescribe p, .jobDescribe svg,.careerNav p,#careerDetail,#careerNumb p,#careerNumb p svg, #about,#hear,#chat,#explore, #aboutCompany,#flexible,#historyData,#quotRequest,input,textarea, #quotReach, #services,.quotationForm label{
       color: white !important;
   } 
   .careerNav .activeCareer{
       background-color: #3B6DEA !important;
       color: white !important;
   }
   .jobDescribe  .strech{
       justify-content: unset !important;
   }
   #uploadIcn svg, .strech .contDiv p, .serviceForm label, #aboutNav svg, #aboutNav span,#jobName,#jobDetail{
       color: white !important;


   }
   #teamMember .dark-img ,.myService   .dark-img{
    width: 80px;
    height: 80px; 
   }
   #teamMember  .dark-img img  ,.myService .dark-img img{
    width: 35px;
    height: 35px; 
   }
   .dark-img{
  display: inline-flex;
//   background-color:  #32436c;
// border: 14.5px solid #22325B ;
  
//   border-radius: 100%;
//   width: 124.718px;
// height: 124.718px;
justify-content: center;
align-items: center;
margin: 16px 0px;
}
 .aboutUs-container{
     background-color: #061539;
 }

.activeSquare .dark-img{
border: none;
width: 96.718px;
background-color: #214397;
height: 96.718px;
}
.dark-img img {
    margin-bottom: 0;

}
   .serviceForm{
    background: rgba(255, 255, 255, 0.10);
   }
   .modal-content{
    background-color: #051539 !important;
   
   }
   .paddingText p{
       color: white !important;
   }
   .successJob{
    background: #0F338C;
   }
   .foodModal, .modalOverlay{
   color: #0F338C;
   }

   .foodModal .modalBody #jobName{
    color: #1851DF !important;


}
   .feedback-container{
    background-color: #051539;

   }
   .feedback-container .modalBody {
       margin-bottom: 0 !important;
       background-color: #051539;
       padding-bottom: 80px;
   }
   #aboutNav a span{

    color: rgba(142, 171, 243, 1) !important;
   }
  
   .aboutUs-container .strech{
     padding-bottom: 10px;
   }
   .quotImg div{
       height: calc(100% + 96px);
   }
   .quotImg{
       padding: 0 !important;
       overflow: hidden;
   }
   #quotDiv{
    background: rgb(11,36,99);
    background: radial-gradient(circle, rgba(11,36,99,1) 3%, rgba(6,21,57,1) 100%);
}
    #uploadImg, .quotationForm input[type=text],  .quotationForm textarea, .serviceForm textarea,.serviceForm input{
    background: rgba(255, 255, 255, 0.10) !important;
   border: none;
   box-shadow: none !important;

   }
   #uploadIcn, #uploadIcn div{
       background-color: #1851DF;
   }
   #uploadImg span{
color: #1851DF;

   }
   .quotation-container{
       background-color: #051539;
   }
   .quotationForm input[type=text]:focus,  .quotationForm textarea:focus, .serviceForm input:focus,.serviceForm input:focus-visible,  .serviceForm textarea:focus{
       box-shadow: none !important;
       border: 1px solid #1851DF !important;
outline: none;
   }
   .careerDiv{
    border: 1px solid #1851DF !important;

   }
   .servicesSlider{
    background: radial-gradient(50% 50% at 50% 50%, #0B2463 0%, #061539 100%);
   }

   #history{
    color: #8EABF3;

   
   }
   #fit1{
    background-image: url("./images/dm1.png") !important;

   }
   #fit2{
    background-image: url("./images/dm2.png") !important;

   }
   #fit3{
    background-image: url("./images/dm3.png") !important;

}
   .our{
    border: 1px solid var(--primary-500, #1851DF);
    background: rgba(255, 255, 255, 0.10);
}
   .whyUs{
    background: radial-gradient(circle, rgba(11,36,99,1) 3%, rgba(6,21,57,1) 100%);
}

   #cliendFeedback{
    border-radius: 16px;
    border: 1px solid var(--gray-200, #E4E7EC);
    background: rgba(255, 255, 255, 0.10);
   }
  
   .dark-container{
    
       background: radial-gradient(113.57% 46.74% at 50% 50%, #0B2463 0%, #061539 100%);
   }
   .seventhDiv{padding: 96px 6vw;
z-index: 1;
background: radial-gradient(circle, rgba(11,36,99,1) 3%, rgba(6,21,57,1) 100%);

position: relative;}

#clients{
    background-color: rgb(5, 21, 57);
    padding-bottom:0 !important;

}

   .homeAr .blackBck{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: auto !important;
    right: 0;
   }
   .marginTopFirst{
    margin-top: 260px;
    margin-bottom: 60px;
   }
   .projDataa{
    background: rgba(255, 255, 255, 0.10);
   }
 
    .square{
        // border: 1px solid  #1851DF !important;
        border: 1px solid #0584C9 !important;
    }
    .activeSquare{
        border: 1px solid  #FCFCFD !important;
        background:  #0F338C !important;
        box-shadow: 0px 0px 24px 0px rgba(15, 51, 140, 0.30) !important;
        }
   .logo{
    background-image: url("./images/whiteLogo.svg") !important;
    height:56px;
    width: 124px;

   }
   #feedbackHeader{
    background: #0B2463;
   }
   .topNavbar select,.links{
       background-color:  transparent ;

   }
   #navbarMargin{
       height: 0;
   }
   .topNavbar{
       border-bottom: 0;
       background: transparent;
       transition: 0.5s;
   }
    .fourthDiv{
        background: radial-gradient(circle, rgba(11,36,99,1) 3%, rgba(6,21,57,1) 100%);
        position: relative;
        display: block;
    }
    #feedDescribe,#feedName,#feedTitle,  .links  p, .topNavbar select, #squareTitle,#powerful, .socialBtns button,#turnIdeas, #doesMore,#doesMore2,#helpBusiness ,#stepText1 ,#stepText2, #dynamic,#dynamic2,.teamLi p{
        color: white;
    }
    
        // .drkImg{
        // //     background-image: url("./images/galaxy2.jpg") ;
        // //     background-size: cover;
        // //     padding-bottom: 20px;
        // //     // animation: shrink 5s infinite alternate steps(60);
        // // }

        // @keyframes shrink {
        //     0% {
        //         transform: scale(1.2)
        //     }
        //     100% {
        //         transform: scale(1.0)
        //     }
        // }

        @keyframes zoomInOut {
            0% {
            transform: scale(1);
            }
            50% {
            transform: scale(1.1);
            }
            100% {
            transform: scale(1);
            }
        }
        
        .drkImg {
            position: relative;
            overflow: hidden;
            display: block;
        }
        
        .drkImg::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background-image: url("./images/galaxy2.jpg") ;
            background-size: cover;
            background-position: center;
            animation: zoomInOut 30s infinite;

            transform: translate3d(0, 0, 0); /* Initial transform for the background only */
            transition: transform 0.5s ease; /* Add a transition for smooth animation -> from 0.8*/
            z-index: -1; /* Set a negative z-index to place it behind other elements */
        }

        /* English background */
        .en-background::before {
            background-image: url("../src/images/galaxy2.jpg");
        }

        .process-background::before {
            background-image: url("../src/images/processBG.png");
        }
        .processFlib-background::before {
            background-image: url("../src/images/processBGFlip.png");
        }
        
        /* Arabic background */
        .ar-background::before {
            background-image: url("../src//images/galaxy2flip.jpg");
        }

        #turnIdeas span{
            background-color: #0B2463;
        }
        .accent, .review, .review2{
            display: none;
        }
        .learnMore{
            background-color: transparent !important;
            color: #3B6DEA !important; 
        }
        .square{
            border-radius: 7.937px;
            // background: rgba(255, 255, 255, 0.04);
            background: #0A163C 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 39.6831px 0px rgba(0, 0, 0, 0.07);
            border: none;
        }
     
        #squareData{
            color: rgba(255, 255, 255);

        }
        .fifthDiv{
            background: rgb(5,21,57);
            margin-top: 0 !important;
            padding-top: 90px !important;
        }
        .stepImg , .mobileStep{
            background:  #3B6DEA !important;
        }
        .stepText{
            background: transparent !important;
        }
        #step1{
            border: 4px solid white;
            width: unset;
            height: unset;
        }
        .swiper-button-next {
            background-image: url("../src/images/arrow-narrow-left.png") !important;

        }
        .swiper-button-prev {
            background-image: url("../src/images/arrow-narrow-right.png") !important;

        }
        #noResult{
            color: white;
        }

        .thirdDiv{
            margin-top: 0;
            padding-bottom: 150px;
            background: radial-gradient(circle, rgba(11,36,99,1) 3%, rgba(6,21,57,1) 100%);
        }

        .swiper-button-next,.swiper-button-prev {
            background-color: #3B6DEA;
        }
        .buildMain{
            // background:  rgb(5,21,57);

          }
          @media screen and (max-width: 1400px){
        .blackBck{
            width: 100vw;
            height: 84vw;
        }
        }

            @media screen and (max-width: 951px){
                .blackBck{
                    height: 148vh;
                    width: 155vh;
                    top: -10vh;
                    left: -12vw;
                }
           .homeAr     .blackBck{
                    left: auto !important;
                  right:-12vw;
                             
                }
            }


            @media screen and (max-width: 915px){
                 .qoute1{
                    width: 130px;
     height:130px
                }
                .qoute1 div{
                    width: 80px !important;
                    height:60px !important
                }
            }
        
         
            @media screen and (max-width: 776px){
                .mobileNav #aboutNav{
                    border: none !important;
                }
            .dark-container    .sixthDiv, #clients,.eightthDiv{
                    margin: 0 !important;
                }
           
                .blackBck{
                    left: -50vw;
    
                }
           .homeAr     .blackBck{
                    left: auto !important;
                  right:-40vw;
                             
                }
            }
        
        @media screen and (max-width: 575px){
             .home-container .footer{
                padding-top: 124px !important;
            }
            .socialLinks{
border: none !important;
            }
            .dark-container    .sixthDiv, #clients,.eightthDiv{
                margin: 0 !important;
            }
       
         
             .fifthDiv{
                padding: 32px 6vw !important;
              }
             .marginTopFirst{
margin-top: 160px;
            }
      
            .blackBck{
                height: 147vh;
                left: -62vw;

            }
       .homeAr     .blackBck{
                left: auto !important;
              right:-62vw;
                         
            }
         .qoute1{
            display: none;
          }
        
}
@media screen and (max-width: 450px){
    .blackBck{
        // height: 183vh;
    }
    .drkImg::before {
        animation: unset;
        transform: unset;
        transition: unset;
    }
  .homeAr  .blackBck{
        height: 144vh !important;
right: -76vw;
    }

}
@media screen and (max-width: 400px){
    .homeAr  .blackBck{
right: -92vw;
    } 
}
}
