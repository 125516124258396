@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  animation: float 3s ease-in-out infinite;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #128c7e;
  color: #fff;
}

.whatsapp-button:hover .whatsapp-icon {
  animation: rotate 0.4s forwards;
}

.whatsapp-icon {
  transition: transform 0.3s;
}

@media screen and (max-width: 550px) {
  .whatsapp-button {
    width: 40px;
    height: 40px;
  }
}
