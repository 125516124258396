.products-container {
    background-color: #F1FFFD;
}
.products-container .BdaitBluLogo {
    background-image: url("../images/bdaiatBlueLogo.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    position: absolute;
    top: 4em;
    left: 4em;
}


.besalla .besallaTextCol {
    text-align: left;
    padding: 10em 0em 4em 8em;
}
.besalla .arabicStyleBesallaTextCol {
    text-align: right;
    padding: 10em 8em 4em 0em;
}
.besalla .besallaTextCol .besallaLogo {
    background-image: url("../images/besallsLogo.png");
    background-size: contain;
    background-position: right;
    height: 6em;
    background-repeat: no-repeat;
    /* margin-left: 22em; */
    margin-bottom: 5em;
}
.besalla .besallaTextCol .besallaText h1 {
    color: #1183A5;
    font-size: 51px;
    font-family: 'Cairo';
    font-weight: bold;
}
.besalla .besallaTextCol .arabicStyleBesallaTextH1 {
    color: #1183A5;
    font-size: 56px;
    font-family: 'Cairo';
    font-weight: bold;
}
.besalla .besallaTextCol .besallaText p {
    color: #18AED9;
    font-size: 33px;
    font-family: 'Cairo';
    font-weight: bold;
}
.besalla .besallaTextCol .besallaText .besallaTextP2 {
    color: #0E0E0E;
    font-size: 25px;
    font-family: 'Cairo';
    font-weight: bold;
}
.besalla .besallaTextCol .besallaText .arabicStyleBesallaInput { text-align: right; }
.besalla .besallaTextCol .besallaText input {
    padding: 15px;
    border-radius: 15px;
    border: 2px solid #000000;
    text-align: left;
    color: #747474 !important;
    width: 60%;
}
.besalla .besallaTextCol .besallaText button {
    padding: 15px 65px;
    border: 2px solid #000000;
    border-radius: 10px;
    background-color: #000000;
    color: #FFF302;
    font-weight: bold;
    font-family: 'Cairo';
    font-weight: bold;
    /* position: absolute; */
    /* margin-left: -4em; */
}
.besalla .besallaTextCol .besallaText button:hover {
    background-color: #FFF302;
    color: #000000;
}
.besalla .besallaTextCol .besallaText button:active { border: 2px solid #FFF302; }

.besalla .besallaImgsCol {
    position: relative;
    padding: 10em 0em 3em 0px;
}
.besalla .besallaImgsCol .bsImgOne {
    background-image: url("../images/chefFlipped.png");
    background-size: contain;
    background-position: right;
    width: 100%;
    height: 35em;
    background-repeat: no-repeat;
    /* margin-left: -115px; */
}
.besalla .besallaImgsCol .arabicStyleBesallaImgsOne {
    background-image: url("../images/chef.png");
    background-size: contain;
    background-position: left;
    width: 100%;
    height: 35em;
    background-repeat: no-repeat;
    /* margin-left: -115px; */
}
.besalla .besallaImgsCol .bsImgTwo {
    position: absolute;
    top: 15em;
    right: 7em;
    z-index: 1;

    background-image: url("../images/besallaMob.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 33em;
    background-repeat: no-repeat;
}
.besalla .besallaImgsCol .arabicStyleBesallaImgsTwo {
    position: absolute;
    top: 15em;
    left: 3em;
    z-index: 1;

    background-image: url("../images/besallaMob.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 33em;
    background-repeat: no-repeat;
    /* margin-top: -25em;
    margin-left: 3em; */
}


.aboutBesalla { 
    background-color: #FFFFFF;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    font-family: 'Cairo';
    padding-bottom: 3em;
}
.aboutBesalla .besallaTextCol {
    padding: 6em 0em 0em 8em;
}
.aboutBesalla .arabicStyleAboutBesallaTextCol {
    padding: 6em 8em 0em 0em;
}
.aboutBesalla .besallaTextCol .besallaLogo {
    background-image: url("../images/besallsLogo.png");
    background-size: contain;
    background-position: right;
    height: 8em;
    background-repeat: no-repeat;
    /* margin-left: 60em; */
    /* margin-right: 7em; */
    margin-bottom: 3em;
}
.aboutBesalla .besallaTextCol .besallaText h1 {
    color: #1183A5;
    font-size: 45px;
    font-family: 'Cairo';
    font-weight: bolder;
    text-align: right;
    /* padding-right: 2.5em; */
}
.aboutBesalla .aboutCol1{
    padding: 3em 10em 5em 0em;
    text-align: left;
    direction: ltr;
}
.aboutBesalla .arabicStyleAboutCol1 {
    padding: 3em 0em 5em 10em;
    text-align: right;
    direction: rtl;
}
.aboutBesalla .aboutCol2 {
    padding: 3em 5em 5em 8em;
    text-align: left;
    direction: ltr;
}
.aboutBesalla .arabicStyleAboutCol2 {
    padding: 3em 8em 5em 5em;
    text-align: right;
    direction: rtl;
}
.aboutBesalla .aboutCol3{
    padding: 0em 10em 5em 0em;
    text-align: left;
    direction: ltr;
}
.aboutBesalla .arabicStyleAboutCol3 {
    padding: 0em 0em 5em 10em;
    text-align: right;
    direction: rtl;
}
.aboutBesalla .aboutCol4 {
    padding: 0em 5em 5em 8em;
    text-align: left;
    direction: ltr;
}
.aboutBesalla .arabicStyleAboutCol4 {
    padding: 0em 8em 5em 5em;
    text-align: right;
    direction: rtl;
}
.aboutBesalla .aboutColRes { 
    padding: 0em 13em 5em 0em;
    text-align: left;
}
.aboutBesalla .arabicStyleAboutColRes {
    padding: 0em 0em 5em 13em;
    text-align: right;
}
.aboutBesalla .aboutCol5 {
    padding: 1em 2em 0em 2em;
    /* text-align: right; */
    border: 2px solid #000000;
    border-radius: 10px;
    box-shadow: 5px 5px 0 0 #fef30d;
    /* width: 73%;
    margin-left: 13em; */
}
.aboutBesalla .aboutCol6 {
    padding: 0em 5em 5em 8em;
    text-align: left;
    direction: ltr;
}
.aboutBesalla .arabicStyleAboutCol6 {
    padding: 3em 8em 5em 5em;
    text-align: right;
    direction: rtl;
}
.aboutBesalla .aboutCol2 .bsAboutImg2 {
    background-image: url("../images/housesMaintain.png");
    background-size: contain;
    background-position: right;
    height: 5em;
    background-repeat: no-repeat;
    margin-bottom: 2em;
    /* margin-left: 22em; */

}
.aboutBesalla .aboutCol1 .bsAboutImg1 {
    background-image: url("../images/menuLogo.png");
    background-size: contain;
    background-position: right;
    width: 100%;
    height: 5em;
    background-repeat: no-repeat;
    margin-bottom: 2em;
    /* margin-left: 16em; */
}
.aboutBesalla .aboutCol4 .bsAboutImg4 {
    background-image: url("../images/micLogo.png");
    background-size: contain;
    background-position: right;
    height: 5em;
    /* width: 6em; */
    background-repeat: no-repeat;
    margin-bottom: 2em;
    /* margin-left: 27em; */
}
.aboutBesalla .aboutCol3 .bsAboutImg3 {
    background-image: url("../images/peopleLogo.png");
    background-size: contain;
    background-position: right;
    /* width: 100%; */
    height: 5em;
    background-repeat: no-repeat;
    margin-bottom: 2em;
    /* margin-left: 12em; */
}
.aboutBesalla .aboutCol6 .bsAboutImg6 {
    background-image: url("../images/verfiedLogo.png");
    background-size: contain;
    background-position: right;
    height: 5em;
    /* width: 8em; */
    background-repeat: no-repeat;
    margin-bottom: 2em;
    /* margin-left: 21em; */
}
.aboutBesalla .aboutCol5 .bsAboutImg5 {
    background-image: url("../images/besallsLogo.png");
    background-size: contain;
    background-position: right;
    /* width: 80%; */
    height: 5em;
    background-repeat: no-repeat;
    margin-bottom: 1em;
    /* margin-left: 9em; */
}
.aboutBesalla .aboutCol1 .bsAboutHeader1 h1, .aboutBesalla .aboutCol2 .bsAboutHeader2 h1, .aboutBesalla .aboutCol3 .bsAboutHeader3 h1,
.aboutBesalla .aboutCol4 .bsAboutHeader4 h1, .aboutBesalla .aboutCol5 .bsAboutHeader5 h1, .aboutBesalla .aboutCol6 .bsAboutHeader6 h1 {
    color: #000000;
    font-size: 35px;
    font-family: 'Cairo';
    /* text-align: right; */
    font-weight: bold;
    margin-bottom: 25px;
}
.aboutBesalla .aboutCol1 .bsAboutP1 p, .aboutBesalla .aboutCol2 .bsAboutP2 p, .aboutBesalla .aboutCol3 .bsAboutP3 p,
.aboutBesalla .aboutCol4 .bsAboutP4 p, .aboutBesalla .aboutCol5 .bsAboutP5 p, .aboutBesalla .aboutCol6 .bsAboutP6 p,
.aboutBesalla .aboutCol5 .bsAboutP7 p{
    color: #747474;
    font-size: 20px;
    font-family: 'Cairo';
    /* text-align: right; */
    font-weight: bold;
    margin-bottom: 25px;
    /* text-align: right; */
}
.aboutBesalla .aboutCol5 .bsAboutP7 button {
    padding: 15px;
    border: 2px solid #000000;
    border-radius: 10px;
    font-size: 15px;
    background-color: #000000;
    color: #FFF302;
    font-weight: bold;
    font-family: 'Cairo';
    margin-left: 3em;
}
.aboutBesalla .aboutCol5 .bsAboutP7 .arabicStyleBsAboutP7Btn {
    margin-right: 8em;
    margin-left: 0em;
}
.aboutBesalla .aboutCol5 .bsAboutP7 button:hover {
    background-color: #FFF302;
    color: #000000;
}


.besallaEnd { 
    background-color: #F1FFFD;
    font-family: 'Cairo';
}
.besallaEnd .besallaImgsCol .bsImgTwo {
    background-image: url("../images/besallaMob.png");
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 28em;
    background-repeat: no-repeat;
}
.besallaEnd .besallaTextCol {
    text-align: left;
    padding: 5em 0em 0em 8em;
}
.besallaEnd .arabicStyleBesallaTextCol {
    text-align: right;
    padding: 5em 8em 0em 0em;
}
.besallaEnd .besallaTextCol .besallaText h1 {
    color: #000000;
    font-size: 35px;
    font-family: 'Cairo';
    text-align: right;
    font-weight: bold;
    margin-bottom: 1em;
}
.besallaEnd .besallaTextCol .besallaText .besallaLogo {
    background-image: url("../images/besallsLogo.png");
    background-size: contain;
    background-position: left;
    width: 50%;
    height: 8em;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: -7em;
}
.besallaEnd .besallaTextCol .besallaText .arabicStyleEndBesallaLogo {
    background-image: url("../images/besallsLogo.png");
    background-size: contain;
    background-position: left;
    width: 50%;
    height: 8em;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: -7em;
}
.besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo {
    background-image: url("../images/whatsAppYellow.png");
    background-size: contain;
    background-position: left;
    width: 50%;
    height: 9em;
    background-repeat: no-repeat;
    display: inline-block;
    /* margin-right: -5.5em;
    margin-top: 2em; */
}
.besallaEnd .besallaTextCol .besallaText .arabicStyleBesallaWhatsLogo {
    background-image: url("../images/whatsAppYellow.png");
    background-size: contain;
    background-position: right;
    width: 50%;
    height: 9em;
    background-repeat: no-repeat;
    display: inline-block;
    /* margin-right: -5.5em;
    margin-top: 2em; */
}

.footer {direction: ltr;}
.arabicStyleProductsFooter {direction: rtl;}

@media screen and (max-width: 1400px){
    .products-container .BdaitBluLogo {     top: 5em;    }
    .besalla .besallaTextCol { padding: 10em 3em 4em 2em; }
    .besalla .besallaTextCol .besallaLogo {
        height: 6em;
        margin-left: 0em;
        margin-bottom: 4em;
    }
    .besallaEnd .besallaTextCol .besallaText .besallaLogo {
        margin-right: 0em;
        margin-left: 0em;
        height: 7em;
    }
    .besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo { height: 7em; }
    .aboutBesalla .aboutColRes { padding: 0em 6em 5em 0em; }
    .aboutBesalla .arabicStyleAboutColRes {padding: 0em 0em 5em 6em;}
    .besalla .besallaImgsCol .bsImgTwo {
        right: 5em;
        height: 35em;
    }
}
@media screen and (max-width: 992px){
    .aboutBesalla .aboutCol1 { padding: 3em 5em 5em 6em; }
    .aboutBesalla .aboutCol2 { padding: 3em 5em 5em 5em; }
    .aboutBesalla .aboutCol3 { padding: 0em 5em 5em 6em; }
    .aboutBesalla .aboutCol4 { padding: 0em 5em 5em 5em; }
    .aboutBesalla .aboutCol5 {
        margin-left: 0em;
        width: 100%;
    }
    .aboutBesalla .aboutCol6 { padding: 5em 5em 5em 6em; }
    /* .aboutBesalla .aboutColRes { padding: 0em 5em 5em 25em; } */
    .aboutBesalla .aboutColRes {padding: 0em 5em 5em 5em;}
    .aboutBesalla .besallaTextCol .besallaLogo { margin-right: 4em; }
    .aboutBesalla .besallaTextCol .besallaText { margin-right: 4em; }
    .aboutBesalla .arabicStyleAboutBesallaTextCol { padding: 6em 1em 0em 0em; }
    .aboutBesalla .besallaTextCol {padding: 6em 0em 0em 5em;}
    .besallaEnd .besallaTextCol { padding: 5em 0em 0em 4em; }
    .besallaEnd .arabicStyleBesallaTextCol { padding: 5em 4em 0em 0em; }
}
@media screen and (max-width: 951px){

}
@media screen and (max-width: 895px){

}
@media screen and (max-width: 767px){
    .besalla .besallaImgsCol .bsImgTwo { right: 0em; }
    .besalla .besallaImgsCol .arabicStyleBesallaImgsTwo { left: 0em; }
    /* .besalla .besallaImgsCol .bsImgOne { margin-right: -135px; } */
    /* .besalla .besallaImgsCol .arabicStyleBesallaImgsOne { margin-left: -135px; } */
    .besalla .besallaTextCol { padding: 5em 3em 4em 2em; }
    .besallaEnd .besallaTextCol { padding: 5em 5em 4em 5em; }
    .besallaEnd .arabicStyleBesallaTextCol { padding: 5em 5em 4em 5em; }
}
@media screen and (max-width: 540px){
    .aboutBesalla .aboutColRes { padding: 0em 5em 5em 3em; }
    .aboutBesalla .besallaTextCol .besallaText h1 {font-size: 35px;}
    .aboutBesalla .besallaTextCol .besallaLogo {height: 7em;}
    .aboutBesalla .besallaTextCol {padding: 6em 0em 0em 1em;}
}
@media screen and (max-width: 480px){
    .aboutBesalla .besallaTextCol .besallaLogo { margin-right: 1em; }
    .aboutBesalla .besallaTextCol .besallaText { margin-right: 1em; }
    .aboutBesalla .aboutCol1 { padding: 3em 2em 5em 1em; }
    .aboutBesalla .aboutCol2 { padding: 3em 2em 5em 1em; }
    .aboutBesalla .aboutCol3 { padding: 0em 2em 5em 1em; }
    .aboutBesalla .aboutCol4 { padding: 0em 2em 5em 1em; }
    .aboutBesalla .aboutCol5 { padding: 1em 1em 0em 1em; }
    .aboutBesalla .aboutColRes { padding: 0em 2em 5em 1em; }
    .aboutBesalla .aboutCol6 { padding: 5em 2em 2em 2em; }
    .aboutBesalla .aboutCol5 .bsAboutHeader5 h1 { font-size: 30px; }
    .aboutBesalla .aboutCol5 .bsAboutP5 p, .aboutBesalla .aboutCol5 .bsAboutP7 p{ font-size: 18px; }
    .aboutBesalla .aboutCol5 .bsAboutP7 button { 
        font-size: 15px;
        margin-right: 0em;
    }
    .besallaEnd .besallaTextCol { padding: 5em 2em 4em 2em; }
    .aboutBesalla .arabicStyleAboutBesallaTextCol { padding: 6em 1em 0em 4em; }
    .besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo { height: 6em; }
}
@media screen and (max-width: 400px) {
    .aboutBesalla .aboutCol5 .bsAboutHeader5 h1 { font-size: 30px; }
    .aboutBesalla .aboutCol5 .bsAboutP5 p, .aboutBesalla .aboutCol5 .bsAboutP7 p{ font-size: 15px; }
    .aboutBesalla .aboutCol5 .bsAboutP7 button { 
        font-size: 12px;
        margin-right: 0em;
        margin-left: 1em;
    }
    .besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo { height: 5em; }
}