.products-container {
  background-color: #051639;
}
.products-container .BdaitBluLogo {
  background-image: url("../images/bdaiatBlueLogo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 4em;
  left: 4em;
}

.besalla .besallaTextCol {
  text-align: left;
  padding: 10em 0em 4em 8em;
}
.besalla .arabicStyleBesallaTextCol {
  text-align: right;
  padding: 10em 8em 4em 0em;
}
.besalla .besallaTextCol .besallaLogo {
  background-image: url("../images/besallsLogo.png");
  background-size: contain;
  background-position: right;
  height: 6em;
  background-repeat: no-repeat;
  /* margin-left: 22em; */
  margin-bottom: 5em;
}
.besalla .besallaTextCol .besallaText {
  text-align: left;
  direction: ltr;
}
.besalla .besallaTextCol .arabicStyleBesallaText {
  text-align: right;
  direction: rtl;
}
.besalla .besallaTextCol .besallaText h1 {
  color: #ffffff;
  font-size: 60px;
  font-family: "Cairo";
  font-weight: bold;
}
.besalla .besallaTextCol .arabicStyleBesallaTextH1 {
  color: #1183a5;
  font-size: 56px;
  font-family: "Cairo";
  font-weight: bold;
}
.besalla .besallaTextCol .besallaText p {
  color: #2680ea;
  font-size: 51px;
  font-family: "Cairo";
  font-weight: bold;
}
.besalla .besallaTextCol .besallaText .besallaTextP2 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Cairo";
  font-weight: bold;
  font-weight: 300;
  padding-right: 6em;
  padding-left: 0em;
  margin-bottom: 4em;
}
.besalla .besallaTextCol .besallaText .arabicStyleBesallaTextP2 {
  color: #ffffff;
  font-size: 18px;
  font-family: "Cairo";
  font-weight: bold;
  font-weight: 300;
  padding-left: 6em;
  padding-right: 0em;
  margin-bottom: 4em;
}
.besalla .besallaTextCol .besallaText .arabicStyleBesallaInput {
  text-align: right;
}
.besalla .besallaTextCol .besallaText input {
  padding: 15px;
  border-radius: 15px;
  border: 2px solid #000000;
  text-align: left;
  color: #747474 !important;
  width: 60%;
}
.besalla .besallaTextCol .besallaText button {
  padding: 15px 65px;
  border: 2px solid #000000;
  border-radius: 10px;
  background-color: #000000;
  color: #fff302;
  font-weight: bold;
  font-family: "Cairo";
  font-weight: bold;
  /* position: absolute; */
  /* margin-left: -4em; */
}
.besalla .besallaTextCol .besallaText button:hover {
  background-color: #fff302;
  color: #000000;
}
.besalla .besallaTextCol .besallaText button:active {
  border: 2px solid #fff302;
}

.besalla .besallaImgsCol {
  position: relative;
  padding: 7em 0em 0em 0px;
}
.besalla .besallaImgsCol .bsImgOne {
  background-image: url("../images/smouhaLanding.png");
  background-size: contain;
  background-position: right;
  width: 100%;
  height: 34em;
  background-repeat: no-repeat;
  /* margin-left: -115px; */
}
.besalla .besallaImgsCol .arabicStyleBesallaImgsOne {
  background-image: url("../images/smouhaLanding.png");
  background-size: contain;
  background-position: left;
  width: 100%;
  height: 34em;
  background-repeat: no-repeat;
  /* margin-left: -115px; */
}
.besalla .besallaImgsCol .bsImgTwo {
  position: absolute;
  top: 15em;
  right: 7em;
  z-index: 1;

  background-image: url("../images/besallaMob.png");
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 33em;
  background-repeat: no-repeat;
}
.besalla .besallaImgsCol .arabicStyleBesallaImgsTwo {
  position: absolute;
  top: 15em;
  left: 3em;
  z-index: 1;

  background-image: url("../images/besallaMob.png");
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 33em;
  background-repeat: no-repeat;
  /* margin-top: -25em;
    margin-left: 3em; */
}

.smouhaStores {
  display: flex;
  justify-content: flex-start; /* Aligns content to the right */
}
.store {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust the gap between the images */
}
.store img {
  width: 150px; /* Adjust the size as needed */
  height: auto;
}

.footer {
  direction: ltr;
}
.arabicStyleProductsFooter {
  direction: rtl;
}

@media screen and (max-width: 1400px) {
  .products-container .BdaitBluLogo {
    top: 5em;
  }
  .besalla .besallaTextCol {
    padding: 10em 3em 4em 2em;
  }
  .besalla .besallaTextCol .besallaLogo {
    height: 6em;
    margin-left: 0em;
    margin-bottom: 4em;
  }
  .besallaEnd .besallaTextCol .besallaText .besallaLogo {
    margin-right: 0em;
    margin-left: 0em;
    height: 7em;
  }
  .besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo {
    height: 7em;
  }
  .aboutBesalla .aboutColRes {
    padding: 0em 6em 5em 0em;
  }
  .aboutBesalla .arabicStyleAboutColRes {
    padding: 0em 0em 5em 6em;
  }
  .besalla .besallaImgsCol .bsImgTwo {
    right: 5em;
    height: 35em;
  }
}
@media screen and (max-width: 992px) {
  .aboutBesalla .aboutCol1 {
    padding: 3em 5em 5em 6em;
  }
  .aboutBesalla .aboutCol2 {
    padding: 3em 5em 5em 5em;
  }
  .aboutBesalla .aboutCol3 {
    padding: 0em 5em 5em 6em;
  }
  .aboutBesalla .aboutCol4 {
    padding: 0em 5em 5em 5em;
  }
  .aboutBesalla .aboutCol5 {
    margin-left: 0em;
    width: 100%;
  }
  .aboutBesalla .aboutCol6 {
    padding: 5em 5em 5em 6em;
  }
  /* .aboutBesalla .aboutColRes { padding: 0em 5em 5em 25em; } */
  .aboutBesalla .aboutColRes {
    padding: 0em 5em 5em 5em;
  }
  .aboutBesalla .besallaTextCol .besallaLogo {
    margin-right: 4em;
  }
  .aboutBesalla .besallaTextCol .besallaText {
    margin-right: 4em;
  }
  .aboutBesalla .arabicStyleAboutBesallaTextCol {
    padding: 6em 1em 0em 0em;
  }
  .aboutBesalla .besallaTextCol {
    padding: 6em 0em 0em 5em;
  }
  .besallaEnd .besallaTextCol {
    padding: 5em 0em 0em 4em;
  }
  .besallaEnd .arabicStyleBesallaTextCol {
    padding: 5em 4em 0em 0em;
  }
}
@media screen and (max-width: 951px) {
}
@media screen and (max-width: 895px) {
}
@media screen and (max-width: 767px) {
  .besalla .besallaImgsCol {
    padding: 5em 1em 0em 1em;
  }
  .besalla .besallaImgsCol .bsImgTwo {
    right: 0em;
  }
  .besalla .besallaImgsCol .arabicStyleBesallaImgsTwo {
    left: 0em;
  }
  /* .besalla .besallaImgsCol .bsImgOne { margin-right: -135px; } */
  /* .besalla .besallaImgsCol .arabicStyleBesallaImgsOne { margin-left: -135px; } */
  .besalla .besallaTextCol {
    padding: 5em 3em 4em 2em;
  }
  .besallaEnd .besallaTextCol {
    padding: 5em 5em 4em 5em;
  }
  .besallaEnd .arabicStyleBesallaTextCol {
    padding: 5em 5em 4em 5em;
  }
  .phone-field {
    order: 1;
  }
  .textarea-field {
    order: 2;
  }
}
@media screen and (max-width: 540px) {
  .aboutBesalla .aboutColRes {
    padding: 0em 5em 5em 3em;
  }
  .aboutBesalla .besallaTextCol .besallaText h1 {
    font-size: 35px;
  }
  .aboutBesalla .besallaTextCol .besallaLogo {
    height: 7em;
  }
  .aboutBesalla .besallaTextCol {
    padding: 6em 0em 0em 1em;
  }
  .besalla .besallaTextCol .besallaText .arabicStyleBesallaTextP2 {
    padding-left: 0em;
  }
  .besalla .besallaTextCol .besallaText .besallaTextP2 {
    padding-right: 0em;
  }
  .besalla .besallaImgsCol .arabicStyleBesallaImgsOne {
    background-position: center;
    height: 25em;
  }
}
@media screen and (max-width: 480px) {
  .aboutBesalla .besallaTextCol .besallaLogo {
    margin-right: 1em;
  }
  .aboutBesalla .besallaTextCol .besallaText {
    margin-right: 1em;
  }
  .aboutBesalla .aboutCol1 {
    padding: 3em 2em 5em 1em;
  }
  .aboutBesalla .aboutCol2 {
    padding: 3em 2em 5em 1em;
  }
  .aboutBesalla .aboutCol3 {
    padding: 0em 2em 5em 1em;
  }
  .aboutBesalla .aboutCol4 {
    padding: 0em 2em 5em 1em;
  }
  .aboutBesalla .aboutCol5 {
    padding: 1em 1em 0em 1em;
  }
  .aboutBesalla .aboutColRes {
    padding: 0em 2em 5em 1em;
  }
  .aboutBesalla .aboutCol6 {
    padding: 5em 2em 2em 2em;
  }
  .aboutBesalla .aboutCol5 .bsAboutHeader5 h1 {
    font-size: 30px;
  }
  .aboutBesalla .aboutCol5 .bsAboutP5 p,
  .aboutBesalla .aboutCol5 .bsAboutP7 p {
    font-size: 18px;
  }
  .aboutBesalla .aboutCol5 .bsAboutP7 button {
    font-size: 15px;
    margin-right: 0em;
  }
  .besallaEnd .besallaTextCol {
    padding: 5em 2em 4em 2em;
  }
  .aboutBesalla .arabicStyleAboutBesallaTextCol {
    padding: 6em 1em 0em 4em;
  }
  .besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo {
    height: 6em;
  }
}
@media screen and (max-width: 400px) {
  .aboutBesalla .aboutCol5 .bsAboutHeader5 h1 {
    font-size: 30px;
  }
  .aboutBesalla .aboutCol5 .bsAboutP5 p,
  .aboutBesalla .aboutCol5 .bsAboutP7 p {
    font-size: 15px;
  }
  .aboutBesalla .aboutCol5 .bsAboutP7 button {
    font-size: 12px;
    margin-right: 0em;
    margin-left: 1em;
  }
  .besallaEnd .besallaTextCol .besallaText .WhatsappYellowLogo {
    height: 5em;
  }
  .besalla .besallaTextCol .besallaText h1 {
    text-align: center;
  }
  .besalla .besallaTextCol .besallaText .besallaTextP2,
  .besalla .besallaTextCol .besallaText .arabicStyleBesallaTextP2 {
    text-align: center;
  }
  .besalla .besallaTextCol .besallaText p {
    text-align: center;
  }
  .besalla .besallaTextCol {
    padding: 0em 2em 2em 2em;
  }
}
