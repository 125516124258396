.service{
    height: 100% !important;
}
.serviceForm{
    background: #F9FAFB;
border-radius: 8px;
padding: 24px;
} 
.serviceForm input{
    background: #FFFFFF;
    /* Gray/300 */
    
    border: 1px solid #d0d5ddb5;
    /* Shadow/xs */
    padding: 0.5em 1em;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 100%;
}
.serviceForm textarea{
    background: #FFFFFF;
    /* Gray/300 */
    padding: 1em;
    border: 1px solid #D0D5DD;
    /* Shadow/xs */
    min-height: 134px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 100%;
}

.serviceForm label{
    font-family: 'Montserrat';
font-style: normal;
margin: 1em 0;
font-weight: 500;
font-size: 14px;
color: #344054;
display: block;
}
.servicesSlider{
    background: #F9FAFB;
    padding: 2em 0 0 6vw;
}
.serviceSlide{
    width: 300px;
    height: 270px;

}
.myService .swiper-slide{
    width: fit-content !important;
}
.myService{
    position: static !important;
}

.myService .swiper-wrapper{
    position: static !important;
}
.myService  .swiper-button-next,   .servicesSlider .swiper-rtl .swiper-button-prev{
left: 5.5em !important;
}
.myService .swiper-button-prev,   .servicesSlider .swiper-rtl .swiper-button-next{
    left: 10px !important;
}
#explore{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 36px;
letter-spacing: -0.02em;

/* Gray/900 */
margin-bottom: 3em;
color: #101828;

}

.myService  .swiper-button-next, .myService  .swiper-button-prev{
    top: 65% !important;
}
#teamMember .square{
    width: 100%;
}

.servicesMobile{
    display: none;
}
@media  screen and (max-width: 1260px){

    #teamMember {
      padding:2em 3vw
  }} 
  @media screen and (max-width: 1106px){
    .myService .swiper-button-next, .myService .swiper-button-prev{
        top: 65% !important;
    }
    .serviceSlide{
        width: 273px;
    }
  }

  @media screen and (max-width: 991px){
    #explore{
        margin-bottom: 1em;
    }
    .servicesMobile{
        display: block;
    }
.serviceSlide{
    display: none;
}
.myService .swiper-button-next, .myService .swiper-button-prev{
    display: none;
}
.servicesMobile .square{
    width: 100%;
}
  }
  @media screen and (max-width: 575px){

  #explore{

    font-size: 30px;
  }}
  .servicesSlider .btnsAr .swiper-button-prev,   .servicesSlider .swiper-rtl .swiper-button-next{
    left: 36vw !important;
    
    }
    .homeAr .servicesSlider{

    }
    .homeAr .myService .swiper-button-next{
right: 5.5em !important;
left: auto !important;
    } 
    
  .homeAr  .myService .swiper-button-prev{
    right: 10px !important;
    left: auto !important;

    }