#careers{
    padding: 6vw;
}
.careerNav p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #667085;
    margin-bottom: 16px;
    cursor: pointer;
    padding: 0.5em;
    border: 1px solid transparent;

}
#plane{
    display: none;
}
.careerDiv:hover{
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}
.careerNav{
    padding-right: 52px;
}
.homeAr .careerNav{
    padding-right: 0 !important;

    padding-left: 52px;
}
.careerNav p:hover{
    color: #3B6DEA;
    border: 1px solid #E4E7EC;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    
    color: #1D2939;
    border-radius: 6px;
}
.careerNav .activeCareer{
    color: #3B6DEA !important;
    border: 1px solid transparent !important;

    background: #F7F9FE;
    border-radius: 6px; 
}
.jobBtn{
    color: white;
}
.jobBtn:hover{
    color: white;
}
.apply{
    margin-top: 1em;
    width: 18%;
    min-width: 150px;
}
 .jobDescribe svg{
margin-right: 8px;
}
.homeAr .jobDescribe svg{
       margin-left: 8px;

    }
.careerDiv{
border: 1px solid #E4E7EC;
padding: 1em;
cursor: pointer;
margin-bottom: 24px;
border-radius: 16px;}
#careerName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
 display: inline-block;
    color: #101828;
    margin-bottom: 8px;
}
.careerType{
    display: inline-flex;
    margin-left: 1em;
    width: fit-content;
}
#careerDetail{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;

color: #475467;
}
#careerArrow{
    width: 56px;
    height: 56px;
    background: #FFFFFF;
border: 1px solid #3B6DEA;
border-radius: 100%; 
display: flex;
justify-content: center;
align-items: center;
transition: 0.7s;
}
#careerArrow svg{
    color: #3B6DEA;
 
}

.careerDiv:hover #careerArrow{
  
    background: #3B6DEA !important;

}
.careerDiv:hover .jobBtn svg{
  
color: white !important;

}

.careerDiv .projType {
    margin-bottom: 8px !important;
}
.careerDiv .careerType{
    margin-left: 8px !important;
}
.homeAr .careerDiv .careerType{
    margin-right: 8px !important; 
       margin-left: auto !important;

}
.careerDiv .projType p{
    font-size: 14px !important;
    font-weight: 500 !important;
}
.activeArrow{
    width: 40px;
    height: 40px;
    background: #3B6DEA !important;
color: white !important;

border: 1px solid #3B6DEA;
border-radius: 50%; 
}
.alignRight{
    display: flex;
    justify-content: end;
    align-items: center;
}
#careerNumb{
    display: flex;
    align-items: center;
}
#careerNumb svg{
margin-right: 8px;
}
.homeAr #careerNumb svg{
    margin-left: 8px;
    }
#careerNumb p{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
color: #475467;
display: flex;
align-items: center;

}
#careerNumb svg{
color: #98A2B3 !important;
}
#aboutNav{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
display: flex;
align-items: center;
color: #98A2B3;
}
.jobDescribe{
    padding: 6vw;
}
#jobName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #1D2939;
    margin-bottom: 32px;
    
}
#jobDesc{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1D2939;
    margin-bottom: 12px;
    
}

#jobDetail{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 24px;
max-width: 80%;
    color: #475467;
    
}
.projData #type1{
    margin-bottom: 16px;
}
.jobDescribe li{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom: 0.5em;
color: #475467;

}

.jobDescribe ul {
    list-style: none;
  }
  

.jobDescribe li::before {
    content: "\2022";
    color: rgb(105, 104, 104);
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }

  .jobDescribe .teamLi p{
margin-bottom: 1em;
  }
  .careerDiv a{
      text-decoration: none;
  }

   
#web{
    color: #5925DC;
    background: #F4F3FF;

}
#web .typeCircle{
    color: #7A5AF8;}
#design{
    color: #175CD3;
    background: #F7F9FE; 
}
#design .typeCircle{
    color: #3B6DEA;}

#ios{
    color: #B93815;
    background: #FFF4ED;
}
#ios .typeCircle{
    color: #EF6820;
}
#android{
    color: rgb(10, 154, 10);
    background: rgba(10, 154, 10, 0.106);

}
#android .typeCircle{
    color: rgb(13, 136, 13);

}

#test{
    color: rgb(255, 222, 33);
    background: rgba(255, 217, 0, 0.242);
}
#test .typeCircle{
    color: rgb(239, 184, 42);

}

  @media screen and (max-width: 1260px){
  
  
    #aboutUsTop{
      padding:6vw 3vw;
  
    }
  }
  .mobileNav{
      display: none;
  }
  .contacts p{
cursor: default;
  }
  .contacts a{
      cursor: pointer;
  }

 
  @media screen and (max-width: 830px){
    .mobileNav{
        display: block;
    }
    .mobileNav #aboutNav{
        padding: 1em 3vw ;
        margin: 0;
display: flex;
border-bottom: 1px solid #F2F4F7;
    }
    #aboutUsTop{
  
    text-align: start;
    } 
    #aboutNav{
        display: none;
    }
    #about{
        margin-bottom: 0;
        font-size: 36px;
        line-height: 44px;
        margin: 0.5em 0;
    }
    #nice{
        margin-bottom: 0;
        font-size: 14px;

    }
    #aboutCompany{
        font-size: 18px;

    }
    #topCover{
        flex-direction: column;
       align-items: start;
       margin: auto;
       display: table;
    }
    #jobName{
        font-weight: 600;
font-size: 30px;
    }

    #jobDesc {
        font-size: 16px;


    }
    .teamLi svg{
        height: 20px;
    }
    .teamLi p{
        font-size: 14px;
    }
  }
  @media  screen and (max-width: 1260px){

  #careers {
    padding:2em 3vw
}}
  @media screen and (max-width: 575px){
   
  .careerNav p{
    font-weight: 400;
    display: inline-block;
margin: 0 1em;
  }
.careerNav{
    white-space: nowrap;
    overflow: scroll;
    margin-bottom: 1.5em;
    height: 50px;
}
.careerDiv{
 
padding: 10px;
}
#careerArrow{
    display: none;
} 
#careerName{
    margin-bottom: 0.5em;
    margin-right: 1em;
    font-weight: 500;
font-size: 18px;
}
.projType{
    margin-bottom: 0.5em;
    margin-left: 0;
    font-weight: 500;
font-size: 14px;
}
}